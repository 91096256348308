<template>
  <div class="addVirtualWallet">
    <div class="settingwarp">
      <header>
        <div class="g-header">
          <div class="left" @click="changego">
            <van-icon name="arrow-left" color="#fff" size="18px" />
          </div>
          <div class="middle fs-18">{{$t('shezhianquanma')}}</div>
          <div class="right">
            <div class="bg-icon bg-icon-setting"></div>
          </div>
        </div>
        <div class="blockHeight"></div>
      </header>
      <div class="g-content">
        <!-- 第一次设置安全码显示这个 -->
        <template v-if="setCode == false">
          <div class="form-wrapper is-not-set">
            <div class="lock-bg">
              <div class="paycode">
                <van-password-input
                  :value="valuenum"
                  :focused="showKeyboard"
                  @focus="showKeyboard = true"
                />
                
				<Keyboard ref="cusBoardss" :length="length"   :defaultVal="defaultVal" v-model:text="valuenum" v-model:showstatus="showstatus"></Keyboard>
              </div>
              <p class="c_a0a0a0">
              {{$t('anquanmaTpsnew')}}
              </p>
            </div>
            <div class="tips">
              <span class="c_a0a0a0">{{$t('anquanmaTpsnewtwo')}}</span>
            </div>
          </div>
        </template>
       
        <!--div class="finish-btn" v-if="setCode == true">
          <van-button
            type="primary"
            round
            block
            class="Btnlogin"
            @click="setSafety"
            >{{$t('queren')}}</van-button
          >
        </div-->
      </div>
    </div>
    <alert-pop
      :alertType="3"
      :Tips="TipsTxt"
      v-if="PopAlert"
      @showAletfalse="showAletfalse"
    ></alert-pop>
  </div>
</template>
<script>
import AlertPop from "@/components/alert/AlertPop.vue";
import userApi from "@/api/user";
import Keyboard from "@/components/keyboard/index.vue";
export default {
  name: "changePassword",
  components: {
    AlertPop,
    Keyboard,
  },
  data() {
    return {
		PopAlert: false,
		setCode: null,
		showKeyboard: true,
		value: "",
		new_code: "",
		after_code: "",
		TipsTxt: this.$t('Tips.szcg'),
		valuenum: "",
		defaultVal: "",
		length: 6,
		showstatus:true,
    };
  },
  watch: {
    valuenum(value) {
		if (value.length === 6 && this.setCode == false) {
			// 调用接口
			this.showKeyboard = false;
			this.$toast.loading({
				message: this.$t('jiazaizhong'),
				forbidClick: true,
				duration: 300,
			});

			userApi
			.setbankSafety(this.valuenum, this.after_code)
			.then((data) => {
				console.log(data.data);
				this.PopAlert = true;
			})
			.catch((err) => {
				this.$toast.fail(this.$t(err.msg));
			});
		}
    }
  },
  methods: {
    showAletfalse() {
      this.PopAlert = false;
      this.$router.go(-1);
    },
    changego() {
      this.$router.go(-1);
    },
    setSafety() {
      if (!this.after_code) {
        this.$toast(this.$t('planquanma'));
      } else if (!this.new_code && this.setCode) {
        this.$toast("plxinanquanma");
      } else {
        this.$toast.loading({
          message: this.$t('jiazaizhong'),
          forbidClick: true,
          duration: 0,
        });

        userApi
          .setSafety(this.new_code, this.after_code)
          .then((data) => {
            console.log(data.data);
            this.$toast.clear();
            this.PopAlert = true;
          })
          .catch((err) => {
            this.$toast.clear();
            this.$toast.fail(this.$t(err.msg));
          });
      }
    },
  },
	mounted() {},
		created() {
			userApi.addressPssword().then((data) => {
				console.log(data)
				this.setCode = data.msg
				console.log(this.setCode);
				if(this.setCode){
					this.$router.push({
						path: "/BankCardManage",
					});
				} else {
					this.$nextTick(() => {
						this.$refs.cusBoardss.show();
						this.valuenum='';
					});
				}
			});
			
		},
	};
</script>
<style lang="less" scoped>
.settingwarp {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.g-content{width: 100%; background-color: #fff;}

.lock-bg {
  position: relative;
  text-align: center;
  width: 9rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 1.8rem;
  background: url(../assets/image/anquanma.png) no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  padding: 0.6rem 0.2rem;
}
.paycode {
  margin-top: 0.2rem;
}
.van-number-keyboard {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #1a243f;
  -webkit-user-select: none;
  user-select: none;
}
.tips {
  width: 8.4rem;
  display: flex;
  margin: 0.4rem auto 1rem;
  align-items: flex-start;
  font-size: 0.32rem;
  img {
    width: 0.4rem;
    margin-right: 0.12rem;
  }
}
.c_a0a0a0 {
  color: #a0a0a0;
}

.addtitle {
  color: #999;
  font-size: .32rem;
  line-height: .32rem;
}

.form{padding: 0.3rem 0.4rem;}

.van-field{width: 100%;}
.van-cell{padding: 10px 0;}
.van-field::after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  bottom: 0;
  border-bottom: .02rem solid #ececec;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}

/deep/.van-field__control {
		color: #000;
		font-size: .32rem;
	}

/deep/ .van-button--round{
		border-radius: 5px;
	}

.finish-btn {
  margin-top: auto;
  margin-bottom: 0.4rem;
  padding:0 0.4rem;
}
.Btnlogin {
  color: #fff;
  background-color: #155db8;
  border: none;
}
</style>