const tw={
	qfh:'THB',
    jiantizhongwen:'Deutsch',
    xianlu:'線路',
    dianwei:'Index',
    wodezichan:'Aktiva',
    touzikuaixun:'Information',
    jiaoyiyonghu:'Benutzer',
    jiaoyipinglei:'Kategorie',
    danlunhuoli:'Profit',
    jiaoyiriqi:'Datum',
    dengluhouchakan:'Ansicht nach Anmeldung',
    shouye:'Titelseite',
    jiaoyidating:'Handelssaal',
    yucun:'Vorspeichern',
    zoushi:'Trend',
    wode:'Persönlich',
    zuiditouzi:'Minimale Investition',
    zuidijinru:'Minimaler Eintritt',
    jichulicai:'Grundlegendes Finanzmanagement',
    zishenlicai:'Leitendes Finanzmanagement',
    dashilicai:'Master Finanzmanagement',
    zhuanjialicai:'Kompetentes Finanzmanagement',
	基础理财:'Grundlegendes Finanzmanagement',
    资深理财:'Leitendes Finanzmanagement',
    大师理财:'Master Finanzmanagement',
    专家理财:'Kompetentes Finanzmanagement',
    huiyuanquanyi:'Vorteile für Mitglieder',
	huiyuanname:'Mitgliedsname',
    zuoriyingli:'Gestern',
    zongzichan:'Bilanzsumme',
    chucunjin:'Kaution',
    jinriyingli:'Heute',
    zhuanchu:'Zurückziehen',
    cunru:'Kaution',
    zhanghuxinxni:'Kontoeinstellungen',
    lishizhangdan:'Investitionsrekord',
    zijinminxi:'Finanzierungsunterlagen',
    renwuzhongxin:'Missionszentrum',
    xiaoxigonggao:'Nachrichtenankündigung',
    tuijianfenxiang:'Teilen empfehlen',
    huodongzhongxin:'Aktivitätszentrum',
    jibenziliao:'Grundinformation',
    dengluzhanghao:'Kontoanmeldung',
    nicheng:'Spitzname',
    shezhi:'Aufstellen',
    genggaixianlu:'Route ändern',
    guanyuwomen:'Firmenprofil',
    xitongshezhi:'Aufstellen',
    yuyanshezhi:'Spracheinstellungen',
    tuichudenglu:'Abmelden',
    bangdingyinhangka:'Bankkarte binden',
    bangdingxuniqianbao:'Virtuelle Geldbörse binden',
    anquanma:'Sicherheitscode',
    xiugailenglumima:'Login-Passwort ändern',
    yinhangkaguanli:'Verwaltung von Bankkarten',
    tianjiayinhangka:'Bankkarte hinzufügen',
    xuniqianbaoguanli:'Verwaltung virtueller Geldbörsen',
    tianjiaxuniqianbao:'Virtuelles Wallet hinzufügen',
    chiakren:'Kartenhalter',
    yinhangkakahao:'Kontonummer',
    yinhangmingcheng:'Bankname',
    yhkTps:'Bitte binden Sie die Bankkarte des Karteninhabers ein',
    nixiqianbaodizhi:'虛擬錢包地址',
    xuniqianbaoleixing:'虛擬錢包類型',
    xnqbTps:' 溫馨提示：請填寫USDT地址類型，如：trc20、erc20、omni。',
    plchikaren:'Bitte den Namen des Karteninhabers eingeben',
    plkahao:'Bitte geben Sie die korrekte Bankkontonummer ein',
    plyinhangmingcheng:'Bitte geben Sie den Banknamen ein',
    plqbdz:'請輸入虛擬錢包地址',
    plqblx:'請輸入虛擬錢包類型',
    shezhianquanma:'Sicherheitscode zurücksetzen',
    yuananquanma:'Originaler Sicherheitscode',
    xinanquanma:'Neuer Sicherheitscode',
    planquanma:'Bitte geben Sie den Original-Sicherheitscode ein',
    plxinanquanma:'Bitte geben Sie einen neuen Sicherheitscode ein',
    queren:'Bestätigen',
	quxiao:'Stornieren',
    anquanmaTps:'Bitte legen Sie einen Sicherheitscode fest, der nicht mit dem Passwort der Bankkarte identisch sein sollte.',
    anquanmaTpsTow:'Sehr geehrter Kunde, dieser Sicherheitscode ist Ihr Passwort bei der Überweisung. Aus Sicherheitsgründen versuchen Sie bitte, nicht mit Ihrem Login-Passwort übereinzustimmen.',
    xiugaidenglu:'Login-Passwort ändern',
    jiumima:'Altes Passwort',
    xinmima:'Neues Kennwort',
    querenmima:'Bestätige das Passwort',
    pljiumima:'Bitte altes Passwort eingeben',
    plxinmima:'Bitte geben Sie ein neues Passwort ein',
    plquerenmima:'Bitte geben Sie das Passwort erneut ein',
    wancheng:'Beenden',
    kaishitouzi:'Fangen Sie an zu investieren',
    kaiTps:'Wenn die aktuelle Leitung nicht verfügbar ist, versuchen Sie, zu einer anderen Leitung zu wechseln',
    zuiyou:'Optimal',
    dangaqianxianlu:'當前線路',
    dangqianbanben:'當前版本',
    banquansuoyou:'版權所有',
    yinglizhendong:'Gewinnschock',
    gensuixitong:'Folgt dem System',
    zhuanchujine:'Überweisungsbetrag',
    plzhuanchu:'Bitte geben Sie den Überweisungsbetrag ein',
    zhuanchuTps:'Bei Fragen wenden Sie sich bitte an den Kundenservice',
    xuanzeyh:'Bitte wählen Sie eine Abhebungsbankkarte aus',
    xuanzehb:'請選擇提款虛擬錢包',
    tijiaoshenqing:'Bewerbung einreichen',
    shuruanquanma:'Bitte geben Sie den Sicherheitscode ein',
    xianshangcunru:'Online einzahlen',
    saomacunru:'USDT',
    wangyincunru:"Online-Banking-Einzahlung",
    shijian:'Zeit',
    upshijian:'Anfangszeit',
    downshijian:'Endzeit',
    wuTps:'Noch keine relevanten Daten~',
    jiazaicx:'Neu laden',
    di:'NO.',
    lun:'輪',
    weiyingli:'Nicht profitabel',
    yishouli:'Akzeptiert',
    zhuangtai:'Staatlich',
    chongzhi:'Zurücksetzen',
    riqi:'Datum',
    CapActive:{
        chongzhi:'Kaution',
        tixian:'Zurückziehen',
        goumai:'Kaufen',
        yingli:'Profit',
        zengsong:'Verschenkt',
        weitongguo:'Nicht bestanden',
        yitongguo:'Bestanden',
        yijujue:'Abgelehnt',
    },
    zhanneixiaoxi:"站内消息",
    pingtaigonggao:'平臺公告',
    fuzhilanjie:"復制鏈接",
    denglu:'Anmeldung',
    jizhumima:'Passwort merken',
    wangjimima:'Passwort vergessen',
    youkedenglu:'遊客登錄',
    zhucezhanghao:"Jetzt registrieren",
    plusername:'Bitte Benutzernamen eingeben',
    plpassword:'Bitte Passwort eingeben',
	plpasswordok:'Bitte geben Sie das Passwort erneut ein',
    wanchengzhuce:'Komplette Registrierung',
    yiyouzhanghao:'Sie haben bereits ein Konto',
    qingdenglu:'Bitte einloggen',
    zhuce:'Registrieren',
    remenhuati:'熱門話題',
    news1:'原創',
    news2:'推薦',
    news3:'財富',
    renliulan:'人瀏覽',
    hezhi:'Summe',
    lunshu:'NO.',
    
    做多:'INR',
    做空:'KRW',
    多单:'CAD',
    多双:'AUD',
    空单:'USD',
    空双:'UER',
    平单:'SGD',
    平双:'GBP',
    极阴:'JPY',
    极阳:'VND',
    qi:'NO.',
    juli:'NO.',
    lunjiezhi:'輪截至',
    yifengpan:'Gesperrt',
    yiguanbi:'Geschlossen',
    yonghu:'Benutzer',
    danlunjiaoyi:'Einzelrundentransaktion',
    caozuo:'Arbeiten',
    pljoin:'Dem Chatroom erfolgreich beigetreten',
    pltingshou:'Produkt wurde eingestellt',
    gengou:"Folgekauf",
	quedinggengou:"Verfolgen Sie den Kauf wirklich?",
	wanjia:"Spieler",
	leibie:"Kategorie",
	jine:"Menge",
    gouru:'kaufen',
    zanweigouru:'Bitte wählen Sie einen Artikel aus und klicken Sie hier, um ihn zu kaufen',
    qigou:'Mindestabnahme',
    gourushuliang:'Kaufmenge',
    changgui:'konventionell',
    shuzi:'Ziffer',
    yinglihezhi:'Gewinnwert',
    shouyijieshao:'Einkommenseinführung',
    lijicunru:'Jetzt einzahlen',
    kaihaojieguo:'Ergebnis der Zahleneröffnung',
    kaihaoqushi:'Eröffnungstrend',
    chanpingshuoming:'Produktbeschreibung',
    qingchuhuancun:'Cache leeren',
    youke:'游客',
	gongxi:'恭喜 ',
	zai:' 在 ',
	yingli:' 盈利 ',
	xiadanchenggong:'Erfolgreich bestellt',
	jiazaizhong:'Laden',
	guanbitg:'Geschlossen, mit dem Kauf aufhören',
	xiangoudl:'Sie können lediglich die aktuelle Kaufrunde verfolgen',
	liaotiantip:'Das System hat Sie gesperrt. Bitte wählen Sie „Kauf“.',
	tishi:'Hinweis',
	zhidao:'Wusste',
	zhuanchusb:'Die Auszahlung ist fehlgeschlagen',
	zhuanchusbs:'Unzureichendes Guthaben, Übertragung fehlgeschlagen',
	buyxq:'Einzelheiten zum Kauf',
	orderNo:'Rechnungsnummer',
	plan_name:'Zimmerinformationen',
	buyxx:'Informationen zum Kauf',
	haoxx:'Details zur Nummernauswahl',
	buysj:'Kaufzeit',
	yilou:'Unterlassung',
	fzjqb:'Auf Pappe kopiert',
	chanpin1:'Verkaufsöffnungszeit: eine Runde alle 3,5 Minuten, Öffnung um 10:00 Uhr an diesem Tag und Schließung um 02:00 Uhr am nächsten Tag.',
	chanpin2:'In jeder Runde gibt es drei Zahlen von 0 bis 9. Die drei Zahlen werden zum Endergebnis addiert. Die Anlageergebnisse können lang, kurz, flach, einfach, flach, bipolar, Anode, Kathode und Zahlen sein.',
	Tips:{
        wjmm:'Wenn Sie Ihr Passwort vergessen haben, wenden Sie sich bitte an den Kundendienst, um es zu ändern!',
        dlcg:'Anmeldung erfolgreich',
        tjcg:'Erfolgreich eingereicht',
        zanwu:'Keine Daten',
        buyizhi:'Zwei Passwörter sind inkonsistent',
        zcbz:'Ihr Vermögen reicht nicht aus, bitte zahlen Sie ein',
        pltxje:'Bitte geben Sie den Auszahlungsbetrag ein',
        zdtx:'Mindestauszahlung 100',
		youke:'Lieber Besucher: Bitte registrieren Sie sich als formelles Mitglied, bevor Sie fortfahren.',
		szcg:'Installation erfolgreich',
    },
	smrz:'實名認證',
	smrzzm:'身份認證正面',
	smrzxm:'姓名',
	smrzhm:'身份證號碼',
	smrztip:'提交成功,等待審核!',
	qwszl:'Bitte vervollständigen Sie die Angaben',
    kfa:'Kundendienstberater',
    kfb:'Kundendienstberater',
    kfc:'Kundendienstberater',
    
	aboutaa:'SENTOFIVE wurde im Februar 2012 von UBS und HSBC gegründet. Seit seiner Gründung hat es die Entscheidungen und Vereinbarungen der Zentrale gewissenhaft umgesetzt, sich auf die Entwicklungsziele „marktorientierter, umfassender, internationaler und konsortialbasierter Unternehmen“ konzentriert und die Entwicklung unbeirrt als oberste Priorität angesehen und dies auch getan hat große Erfolge in der Unternehmensentwicklung, der Bedienung des Finanzmarktes und der Managementinnovation erzielt. gute Ergebnisse erzielt.',
	aboutab:'Bis Ende 2023 hatte die Gruppe ein Betriebsergebnis von 35,95 Milliarden US-Dollar, einen Gesamtgewinn von 2,79 Milliarden US-Dollar, einen Nettogewinn von 2,14 Milliarden US-Dollar, eine Bilanzsumme von 93,23 Milliarden US-Dollar und ein Nettovermögen von 32,86 Milliarden US-Dollar erzielt internationale Kreditratings und internationale S&P AAA-Kreditratings. Ausblick Stabilisieren.',
	
	aboutac:'Künftig wird die Gruppe die Entwicklungsstrategie „248“ mit zwei Transformationsrichtungen, vier Entwicklungszielen, vier Geschäftsbereichen, acht Gleislayouts und acht Kernkompetenzen konsequent umsetzen. Das Ziel besteht darin, acht Kernkompetenzen wie Zentralsteuerung, effiziente Entscheidungsfindung, Teambildung, menschliches Effizienzmanagement, Risikoüberwachung, Risikoprävention, Systemunterstützung und Datenverwaltung aufzubauen und die Gelegenheit zu nutzen, ein neues Kapitel der Transformation und Entwicklung aufzuschlagen.',
    
    aboutad:'In die Zukunft schauen',
    xzyouxishuoming:'Bitte wählen Sie die Anlagerichtung aus',
    kefu:'Kundendienstberater',
    meiyouzhanghao:'kein Account?',
    gongsijieshao:'vorstellen',
    womenshishui:'Wer sind wir？',
	wfcw:'Ihr Guthaben reicht nicht aus, bitte laden Sie es auf',
	ddcw:'Projektfehler',
	dccw:'Falscher Anlagezeitraum',
	decw:'Derzeit geschlossen',
	dfcw:'Bitte wenden Sie sich an den Kundendienst',
	dgcw:'Kauf erfolglos',
	dhcw:'Kauf erfolgreich',
	zdje:'Betrag der Transaktion',
	Invalidparameters:'Ungültiger Parameter',
	Loggedinsuccessful:'Melden Sie sich erfolgreich an',
	Logoutsuccessful:'erfolgreich beenden',
	dicw:'Sie können es nicht mehr bearbeiten. Wenn Sie Änderungen vornehmen müssen, wenden Sie sich bitte an den Kundendienst',
	//zIndex
	djcw:'Der neue Sicherheitscode darf nicht identisch sein',
	dkcw:'Sicherheitscode mit bis zu sechs Ziffern',
	dlcw:'Das alte Passwort ist falsch',
	dmcw:'Sicherheitscode erfolgreich geändert',
	dncw:'Der neue Sicherheitscode darf nicht mit dem alten identisch sein',
	docw:'Sicherheitscode mit bis zu sechs Ziffern',
	dpcw:'Das alte Passwort ist falsch',
	dqcw:'Sicherheitscode erfolgreich geändert',
	drcw:'Das neue Passwort darf nicht mit dem vorherigen Passwort identisch sein',
	dscw:'Bis zu 20 Ziffern',
	dtcw:'kann nicht bearbeitet werden',
	ducw:'Passwort zurücksetzen',
	dvcw:'The guest account has no permissions. Please register as an official account first.',
	dwcw:'Sự cân bằng không chỉ',	
	dxcw:'falsches Passwort',	
	dycw:'Muss aufgebraucht werden',	
	dzcw:'Es liegen offene Anfragen vor',	
	eacw:'Die Auszahlungsinformationen sind falsch',	
	ebcw:'Die Auszahlung ist fehlgeschlagen',	
	eccw:'Die Auszahlung ist fehlgeschlagen',	
	edcw:'Gesendet, bitte warten Sie auf die Bearbeitung',	
	Uploadedsuccessful:'Hochladen erfolgreich',	
	Chunkfiledisabled:'Datei ist deaktiviert',
	qingshuruchakanmaTs:'Bitte geben Sie das Abfragepasswort ein. Sollten Sie es vergessen, wenden Sie sich bitte an den Kundenservice.',
	anquanmaTpsnew:'Bitte geben Sie Ihr Passwort ein, um Ihre Karte anzuzeigen',
	anquanmaTpsnewtwo:'Bitte bewahren Sie Ihr Passwort sicher auf. Wenn Sie Ihr Passwort vergessen haben, wenden Sie sich bitte an den Kundendienst.',
}
export default tw;