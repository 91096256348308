const tw={
	qfh:'VND',
    jiantizhongwen:'Tiếng Việt',
    xianlu:'線路',
    dianwei:'Chỉ số',
    wodezichan:'Tài sản của tôi',
    touzikuaixun:'Thông tin đầu tư',
    jiaoyiyonghu:'Người dùng',
    jiaoyipinglei:'Quốc gia',
    danlunhuoli:'Lợi nhuận',
    jiaoyiriqi:'Ngày tháng',
    dengluhouchakan:'Xem sau khi đăng nhập',
    shouye:'Trang Chủ',
    jiaoyidating:'Giao dịch',
	jiaoyidatingabc:'Sàn giao dịch SENTOfive',
    yucun:'Lưu trước',
    zoushi:'Xu hướng',
    wode:'Tài khoản',
    zuiditouzi:'Đầu tư tối thiểu',
    zuidijinru:'Mục nhập tối thiểu',
    jichulicai:'Quản lý tài chính cơ bản',
    zishenlicai:'Quản lý tài chính cấp cao',
    dashilicai:'Thạc sĩ quản lý tài chính',
    zhuanjialicai:'Quản lý tài chính chuyên nghiệp',
	基础理财:'Quản lý tài chính cơ bản',
    资深理财:'Quản lý tài chính cấp cao',
    大师理财:'Thạc sĩ quản lý tài chính',
    专家理财:'Quản lý tài chính chuyên nghiệp',
    huiyuanquanyi:'ID thành viên',
    zuoriyingli:'Hôm qua',
    zongzichan:'Tổng tài sản',
    chucunjin:'Tiền gửi',
    jinriyingli:'Hôm nay',
    zhuanchu:'Rút tiền',
    cunru:'Nạp tiền',
    zhanghuxinxni:'Thông tin tài khoản',
    lishizhangdan:'Lịch sử giao dịch',
	huiyuanname:'Tài khoản',
    zijinminxi:'Chi tiết quỹ',
    renwuzhongxin:'Trung tâm truyền giáo',
    xiaoxigonggao:'Thông báo tin tức',
    tuijianfenxiang:'Mã mời của tôi',
    huodongzhongxin:'Trung tâm hoạt động',
    jibenziliao:'Thông tin cơ bản',
    dengluzhanghao:'Đăng nhập tài khoản',
    nicheng:'Tên nick',
    shezhi:'Cài đặt',
    genggaixianlu:'更改線路',
    guanyuwomen:'Về chúng tôi',
    xitongshezhi:'Cài đặt hệ thống',
    yuyanshezhi:'Cài đặt ngôn ngữ',
    tuichudenglu:'Đăng xuất',
    bangdingyinhangka:'Liên kết thẻ ngân hàng',
    bangdingxuniqianbao:'Liên kết ví ảo',
    anquanma:'Mã bảo mật',
    xiugailenglumima:'Thay đổi mật khẩu đăng nhập',
    yinhangkaguanli:'Quản lý thẻ ngân hàng',
    tianjiayinhangka:'Quản lý thẻ ngân hàng',
    xuniqianbaoguanli:'Quản lý ví ảo',
    tianjiaxuniqianbao:'Thêm ví ảo',
    chiakren:'Chủ thẻ',
    yinhangkakahao:'Số tài khoản ngân hàng',
    yinhangmingcheng:'Tên ngân hàng',
    yhkTps:'vui lòng liên kết thẻ ngân hàng của bạn',
	tijiaoanniu:'hoàn thành',
    nixiqianbaodizhi:'Địa chỉ ví ảo',
    xuniqianbaoleixing:'Loại ví ảo',
    xnqbTps:'Lời nhắc ấm áp: Vui lòng điền loại địa chỉ USDT, chẳng hạn như: trc20, erc20, omni.',
    plchikaren:'Vui lòng nhập tên chủ thẻ',
    plkahao:'Vui lòng nhập đúng số tài khoản ngân hàng',
    plyinhangmingcheng:'Vui lòng nhập tên ngân hàng',
    plqbdz:'Vui lòng nhập địa chỉ ví ảo',
    plqblx:'Vui lòng nhập loại ví ảo',
    shezhianquanma:'Đặt lại mã bảo mật',
    yuananquanma:'Mã bảo mật gốc',
    xinanquanma:'Mã bảo mật mới',
    planquanma:'Vui lòng nhập mã bảo mật gốc',
    plxinanquanma:'Vui lòng nhập mã bảo mật mới',
    queren:'Xác nhận',
	quxiao:'Hủy bỏ',
    anquanmaTps:'Vui lòng đặt mã bảo mật, không được trùng với mật khẩu thẻ ngân hàng.',
    anquanmaTpsTow:'Kính gửi quý khách hàng, mã bảo mật này chính là mật khẩu của quý khách khi chuyển khoản, vì lý do bảo mật, vui lòng cố gắng không trùng với mật khẩu đăng nhập của mình.',
    xiugaidenglu:'Đặt lại mật khẩu',
    jiumima:'Mật khẩu cũ',
    xinmima:'Mật khẩu mới',
    querenmima:'Xác nhận mật khẩu',
    pljiumima:'Vui lòng nhập mật khẩu cũ',
    plxinmima:'Vui lòng nhập mật khẩu mới',
    plquerenmima:'Vui lòng nhập lại mật khẩu',
    wancheng:'Hoàn thành',
    kaishitouzi:'Bắt đầu đầu tư',
    kaiTps:'Khi đường dây hiện tại không khả dụng, hãy thử chuyển sang đường dây khác',
    zuiyou:'Tối ưu',
    dangaqianxianlu:'Dòng hiện tại',
    dangqianbanben:'Phiên bản hiện tại',
    banquansuoyou:'Đã đăng ký Bản quyền',
    yinglizhendong:'Cú sốc thu nhập',
    gensuixitong:'Theo dõi hệ thống',
    zhuanchujine:'Số tiền chuyển khoản',
    plzhuanchu:'Vui lòng nhập số tiền chuyển',
    zhuanchuTps:'Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với dịch vụ khách hàng',
    xuanzeyh:'Vui lòng chọn thẻ ngân hàng rút tiền',
    xuanzehb:'Vui lòng chọn ví ảo để rút tiền',
    tijiaoshenqing:'rút tiền',
    shuruanquanma:'Vui lòng nhập mã bảo mật',
    xianshangcunru:'Gửi tiền trực tuyến',
    saomacunru:'USDT',
    wangyincunru:"Tiền gửi ngân hàng trực tuyến",
    shijian:'Thời gian',
    upshijian:'Thời gian bắt đầu',
    downshijian:'Thời gian kết thúc',
    wuTps:'Chưa có dữ liệu liên quan~',
    jiazaicx:'Tải lại',
    di:'NO.',
    lun:'',
    weiyingli:'Không có lợi nhuận',
    yishouli:'Đã được chấp nhận',
    zhuangtai:'Tình trạng',
    chongzhi:'Cài lại',
    riqi:'Ngày',
    CapActive:{
        chongzhi:'Nạp tiền',
        tixian:'Rút',
        goumai:'Mua',
        yingli:'Mua',
        zengsong:'Quà',
        weitongguo:'Không vượt qua',
        yitongguo:'Hoàn thành',
        yijujue:'Vật bị loại bỏ',
    },
    zhanneixiaoxi:"Tin tức đài",
    pingtaigonggao:'Thông báo nền tảng',
    fuzhilanjie:"Sao chép đường dẫn",
    denglu:'Đăng nhập',
    jizhumima:'Nhớ mật khẩu',
    wangjimima:'Quên mật khẩu',
    youkedenglu:'Đăng nhập của khách',
    zhucezhanghao:"Đăng ký ngay",
    plusername:'Vui lòng nhập tên người dùng',
    plpassword:'Xin vui lòng nhập mật khẩu',
	plpasswordok:'Vui lòng nhập lại mật khẩu',
    wanchengzhuce:'Hoàn tất đăng ký',
    yiyouzhanghao:'Tôi có một tài khoản',
    qingdenglu:'Làm ơn đăng nhập',
    zhuce:'Đăng ký',
    remenhuati:'Chủ đề nóng',
    news1:'原創',
    news2:'推薦',
    news3:'財富',
    renliulan:'人瀏覽',
    hezhi:'Tổng',
    lunshu:'Số vòng',
    
    做多:'INR',
    做空:'KRW',
    多单:'CAD',
    多双:'AUD',
    空单:'USD',
    空双:'UER',
    平单:'SGD',
    平双:'GBP',
    极阴:'JPY',
    极阳:'VND',
    qi:'期',
    juli:'NO.',
    lunjiezhi:'輪截至',
    yifengpan:'Đã đóng',
    yiguanbi:'Đã đóng',
    yonghu:'Người dùng',
    danlunjiaoyi:'Số lượng',
    caozuo:'Vận hành',
    pljoin:'加入聊天室成功',
    pltingshou:'Sản phẩm đã bị ngừng sản xuất',
    gengou:"Theo sau",
	quedinggengou:"Bạn có chắc chắn làm theo?",
	wanjia:"nhà đầu tư",
	leibie:"loại",
	jine:"Số lượng",
    gouru:'Mua',
    zanweigouru:'Hãy chọn mua',
    qigou:'mua tối thiểu',
    gourushuliang:'Số lượng mua',
    changgui:'常規',
    shuzi:'數位',
    yinglihezhi:'lợi nhuận và giá trị',
    shouyijieshao:'Giới thiệu thu nhập',
    lijicunru:'Gửi tiền ngay',
    kaihaojieguo:'Kết quả mở số',
    kaihaoqushi:'Xu hướng',
    chanpingshuoming:'Mô tả Sản phẩm',
    qingchuhuancun:'Xóa bộ nhớ đệm',
    youke:'游客',
	gongxi:'恭喜 ',
	zai:' 在 ',
	yingli:' 盈利 ',
	xiadanchenggong:'Đặt hàng thành công',
	jiazaizhong:'Đang tải...',
	guanbitg:'Đóng cửa, ngừng mua hàng',
	xiangoudl:'只能跟購當前輪',
	liaotiantip:'系統已禁言，請選擇購入',
	tishi:'Gợi ý',
	zhidao:'Biết',
	zhuanchusb:'Chuyển đi không thành công',
	zhuanchusbs:'Số dư không đủ, chuyển khoản không thành công',
	buyxq:'Chi tiết mua hàng',
	orderNo:'Số hóa đơn',
	plan_name:'Thông tin phòng',
	buyxx:'Mua thông tin',
	haoxx:'Chi tiết lựa chọn số',
	buysj:'Thời gian mua hàng',
	yilou:'Bỏ sót',
	fzjqb:'Đã sao chép vào bảng dán',
	chanpin1:'Thời gian mở bán: 3,5 phút 1 đợt, mở lúc 10h00 ngày hôm đó và kết thúc lúc 02h00 ngày hôm sau.',
	chanpin2:'Mỗi vòng có ba số từ 0 đến 9, ba số này được cộng vào kết quả cuối cùng, kết quả đầu tư có thể là dài, ngắn, phẳng, đơn, phẳng, lưỡng cực, cực dương, cực âm và số.',
	Tips:{
        wjmm:'Nếu bạn quên mật khẩu, vui lòng liên hệ bộ phận chăm sóc khách hàng để thay đổi!',
        dlcg:'Đăng nhập thành công',
        tjcg:'Đã gửi thành công',
        zanwu:'Không có dữ liệu',
        buyizhi:'Hai mật khẩu không nhất quán',
        zcbz:'Tài sản của bạn không đủ, vui lòng gửi tiền',
        pltxje:'Vui lòng nhập số tiền rút',
        zdtx:'Rút tối thiểu 100',
		youke:'Kính gửi quý khách: Vui lòng đăng ký làm thành viên chính thức trước khi tiếp tục.',
		szcg:'Thiết lập thành công',
    },
	smrz:'實名認證',
	smrzzm:'身份認證正面',
	smrzxm:'姓名',
	smrzhm:'身份證號碼',
	smrztip:'提交成功,等待審核!',
	qwszl:'請完善資料',
    kfa:'Dịch vụ khách hàng',
    kfb:'Dịch vụ khách hàng',
    kfc:'Dịch vụ khách hàng',
    
	aboutaa:'SENTOFIVE được thành lập vào tháng 2 năm 2012 bởi UBS và HSBC. Kể từ khi thành lập, nó đã tận tâm thực hiện các quyết định và sắp xếp của trụ sở chính, tập trung vào mục tiêu phát triển của "các doanh nghiệp định hướng thị trường, toàn diện, quốc tế và tập đoàn", đồng thời luôn coi phát triển là ưu tiên hàng đầu và có đạt được nhiều thành tựu to lớn trong phát triển doanh nghiệp, phục vụ thị trường tài chính, đổi mới quản lý và đạt kết quả tốt.',
	aboutab:'Tính đến cuối năm 2023, tập đoàn đã đạt thu nhập hoạt động 35,95 tỷ USD, tổng lợi nhuận 2,79 tỷ USD, lợi nhuận ròng 2,14 tỷ USD, tổng tài sản 93,23 tỷ USD và tài sản ròng 32,86 tỷ USD. xếp hạng tín dụng quốc tế và xếp hạng tín dụng quốc tế S&P AAA.',
	
	aboutac:'Trong tương lai, tập đoàn sẽ thực hiện chắc chắn chiến lược phát triển “248” với hai hướng chuyển đổi, bốn mục tiêu phát triển, bốn lĩnh vực kinh doanh, tám sơ đồ đường đua và tám năng lực cốt lõi. Đó là xây dựng tám năng lực cốt lõi về kiểm soát trụ sở chính, ra quyết định hiệu quả, xây dựng nhóm, quản lý hiệu quả con người, giám sát rủi ro, phòng ngừa rủi ro, hỗ trợ hệ thống và quản trị dữ liệu, đồng thời nắm bắt cơ hội mở ra một chương mới của chuyển đổi và phát triển.',
	
	aboutad:'Nhìn về tương lai',
    xzyouxishuoming:'Hãy chọn hướng đầu tư',
    kefu:'Dịch vụ khách hàng',
	meiyouzhanghao:'Không tài khoản ?',
	gongsijieshao:'Hồ sơ công ty',
	womenshishui:'Chúng ta là ai?',
	wmjs: 'Trong nền kinh tế thế giới đang thay đổi nhanh chóng và ngày càng phức tạp, làm cách nào để chúng ta luôn trung thành với các nguyên tắc cốt lõi của mình: cung cấp dịch vụ khách hàng xuất sắc, thực hiện nhiệm vụ của mình một cách chính trực và có trách nhiệm cũng như hỗ trợ sự phát triển của nhân viên.',
	yejs: 'Công việc kinh doanh của chúng tôi',
	yejsa: 'Công ty chúng tôi là công ty dịch vụ tài chính hàng đầu thế giới về tài sản.',
	yejsb: '2.6',
	yejsc: 'Tỷ đô la và hoạt động toàn cầu',
	yejsd: 'Lịch sử phong phú của chúng tôi kéo dài hơn 200 năm. Chúng tôi là công ty dẫn đầu trong lĩnh vực ngân hàng đầu tư, dịch vụ tài chính tiêu dùng và ngân hàng thương mại dành cho doanh nghiệp nhỏ. Xử lý các giao dịch tài chính và quản lý tài sản.',
	wfcw:'Số dư của bạn không đủ. Vui lòng nạp tiền.',
	ddcw:'Lỗi dự án',
	dccw:'Số kỳ đầu tư bị sai.',
	decw:'Hiện đã nghỉ hưu',
	dfcw:'Thiếu cân bằng Vui lòng liên hệ với dịch vụ khách hàng.',
	dgcw:'Việc mua hàng không thành công.',
	dhcw:'Mua một bản sao',
	zdje:'Số tiền giao dịch',
	Invalidparameters:'thông số không hợp lệ',
	Loggedinsuccessful:'Đăng nhập thành công',
	Logoutsuccessful:'Đăng xuất thành công',
	dicw:'Bạn không thể chỉnh sửa nó nữa. Nếu bạn cần thực hiện thay đổi, vui lòng liên hệ với bộ phận hỗ trợ.',
	//zIndex
	djcw:'Mã bảo mật mới không được giống nhau.',
	dkcw:'Tối đa sáu mã bảo mật',
	dlcw:'Việc sửa lỗi không thành công. Mật khẩu cũ?',
	dmcw:'Mã bảo mật đã được chỉnh sửa.',
	dncw:'Mã bảo mật mới không được giống nhau.',
	docw:'Tối đa sáu mã bảo mật',
	dpcw:'Việc sửa lỗi không thành công. Mật khẩu cũ?',
	dqcw:'Mã bảo mật đã được chỉnh sửa.',
	drcw:'Mật khẩu mới không được giống với mật khẩu trước đó.',
	dscw:'Lên đến 20 chữ số',
	dtcw:'Cập nhật không thành công. Mật khẩu là',
	ducw:'Đặt lại mật khẩu',
	dvcw:'Tài khoản khách không có quyền. Vui lòng đăng ký làm tài khoản chính thức trước.',
	dwcw:'Sự cân bằng không chỉ',	
	dxcw:'Mật khẩu không đúng.',	
	dycw:'Số dư còn lại phải được sử dụng.',	
	dzcw:'Có những yêu cầu chưa được xử lý. Vui lòng liên hệ với bộ phận.',	
	eacw:'Thông tin rút tiền không chính xác.',	
	ebcw:'Rút tiền không thành công',	
	eccw:'Rút tiền không thành công',	
	edcw:'Đã gửi, vui lòng chờ xử lý dịch vụ.',	
	Uploadedsuccessful:'Đã tải lên thành công',	
	Chunkfiledisabled:'Tập tin chunk bị vô hiệu hóa',
	qingshuruchakanmaTs:'Vui lòng nhập mật khẩu truy vấn, nếu quên, vui lòng liên hệ bộ phận chăm sóc khách hàng.',
	anquanmaTpsnew:'Vui lòng nhập thẻ ngân hàng của bạn để xem mật khẩu',
	anquanmaTpsnewtwo:'Vui lòng giữ mật khẩu của bạn an toàn. Nếu bạn quên mật khẩu, vui lòng liên hệ bộ phận chăm sóc khách hàng.',
	'Password is incorrect':'password',
}
export default tw;