const tw={
	qfh:'THB',
    jiantizhongwen:'日本語',
    xianlu:'線路',
    dianwei:'索引',
    wodezichan:'私の資産',
    touzikuaixun:'投資情報',
    jiaoyiyonghu:'ユーザー',
    jiaoyipinglei:'カテゴリー',
    danlunhuoli:'利益',
    jiaoyiriqi:'日付',
    dengluhouchakan:'登陸後查看',
    shouye:'表紙',
    jiaoyidating:'貿易',
    yucun:'デポジット',
    zoushi:'傾向',
    wode:'私の',
    zuiditouzi:'最低投資額',
    zuidijinru:'最低エントリー',
    jichulicai:'基本的な財務管理',
    zishenlicai:'上級財務管理者',
    dashilicai:'マスター財務管理',
    zhuanjialicai:'専門家による財務管理',
	基础理财:'基本的な財務管理',
    资深理财:'上級財務管理者',
    大师理财:'マスター財務管理',
    专家理财:'専門家による財務管理',
    huiyuanquanyi:'メンバーID',
	huiyuanname:'メンバー名',
    zuoriyingli:'昨日の利益',
    zongzichan:'総資産',
    chucunjin:'デポジット',
    jinriyingli:'今日の利益',
    zhuanchu:'撤回する',
    cunru:'デポジット',
    zhanghuxinxni:'口座情報',
    lishizhangdan:'歴史的な請求書',
    zijinminxi:'ファンドの詳細',
    renwuzhongxin:'ミッションセンター',
    xiaoxigonggao:'ニュース発表',
    tuijianfenxiang:'共有を推奨する',
    huodongzhongxin:'アクティビティセンター',
    jibenziliao:'基本情報',
    dengluzhanghao:'ログインアカウント',
    nicheng:'ニックネーム',
    shezhi:'設定',
    genggaixianlu:'更改線路',
    guanyuwomen:'私たちについて',
    xitongshezhi:'システム設定',
    yuyanshezhi:'言語設定',
    tuichudenglu:'サインアウト',
    bangdingyinhangka:'銀行カードをバインドする',
    bangdingxuniqianbao:'綁定虛擬錢包',
    anquanma:'セキュリティコード',
    xiugailenglumima:'ログインパスワードを変更する',
    yinhangkaguanli:'銀行カードの管理',
    tianjiayinhangka:'銀行カードを追加する',
    xuniqianbaoguanli:'虛擬錢包管理',
    tianjiaxuniqianbao:' 添加虛擬錢包',
    chiakren:'カード所有者',
    yinhangkakahao:'銀行の口座番号',
    yinhangmingcheng:'銀行名',
    yhkTps:'カード所有者のキャッシュカードをバインドしてください',
    nixiqianbaodizhi:'虛擬錢包地址',
    xuniqianbaoleixing:'虛擬錢包類型',
    xnqbTps:' 溫馨提示：請填寫USDT地址類型，如：trc20、erc20、omni。',
    plchikaren:'カード所有者名を入力してください',
    plkahao:'正しい銀行口座番号を入力してください',
    plyinhangmingcheng:'銀行名を入力してください',
    plqbdz:'請輸入虛擬錢包地址',
    plqblx:'請輸入虛擬錢包類型',
    shezhianquanma:'セキュリティコードをリセットする',
    yuananquanma:'オリジナルのセキュリティコード',
    xinanquanma:'新しいセキュリティコード',
    planquanma:'元のセキュリティコードを入力してください',
    plxinanquanma:'新しいセキュリティコードを入力してください',
    queren:'確認する',
	quxiao:'キャンセル',
    anquanmaTps:'セキュリティコードを設定してください。キャッシュカードのパスワードと同じであってはなりません。',
    anquanmaTpsTow:'お客様各位 このセキュリティコードは転出時のパスワードとなりますので、セキュリティ上の理由から、ログインパスワードと同じものにならないようにご注意ください。',
    xiugaidenglu:'ログインパスワードを変更する',
    jiumima:'以前のパスワード',
    xinmima:'新しいパスワード',
    querenmima:'パスワードを認証する',
    pljiumima:'古いパスワードを入力してください',
    plxinmima:'新しいパスワードを入力してください',
    plquerenmima:'パスワードをもう一度入力してください',
    wancheng:'仕上げる',
    kaishitouzi:'投資を始める',
    kaiTps:'當前線路無法使用時，可嘗試切換其他線路',
    zuiyou:'最優',
    dangaqianxianlu:'當前線路',
    dangqianbanben:'當前版本',
    banquansuoyou:'版權所有',
    yinglizhendong:'収益ショック',
    gensuixitong:'システムに従ってください',
    zhuanchujine:'払込金額',
    plzhuanchu:'送金額を入力してください',
    zhuanchuTps:'ご不明な点がございましたら、カスタマーサービスまでお問い合わせください',
    xuanzeyh:'引き出し用の銀行カードを選択してください',
    xuanzehb:'出金用の仮想ウォレットを選択してください',
    tijiaoshenqing:'申請書を提出する',
    shuruanquanma:'セキュリティコードを入力してください',
    xianshangcunru:'オンラインで入金',
    saomacunru:'USDT',
    wangyincunru:"網銀存入",
    shijian:'時間',
    upshijian:'開始時間',
    downshijian:'終了時間',
    wuTps:'関連するデータはまだありません~',
    jiazaicx:'リロード',
    di:'NO.',
    lun:'輪',
    weiyingli:'儲からない',
    yishouli:'承認されました',
    zhuangtai:'州',
    chongzhi:'リセット',
    riqi:'日付',
    CapActive:{
        chongzhi:'デポジット',
        tixian:'撤回する',
        goumai:'買う',
        yingli:'買う',
        zengsong:'譲る',
        weitongguo:'不合格',
        yitongguo:'合格した',
        yijujue:'拒否されました',
    },
    zhanneixiaoxi:"站内消息",
    pingtaigonggao:'平臺公告',
    fuzhilanjie:"リンクをコピーする",
    denglu:'ログイン',
    jizhumima:'パスワードを覚える',
    wangjimima:'パスワードを忘れる',
    youkedenglu:'遊客登錄',
    zhucezhanghao:"今すぐサインアップ",
    plusername:'ユーザー名を入力してください',
    plpassword:'パスワードを入力してください',
	plpasswordok:'パスワードをもう一度入力してください',
    wanchengzhuce:'登録を完了する',
    yiyouzhanghao:'すでにアカウントをお持ちですか',
    qingdenglu:'サインインしてください',
    zhuce:'登録する',
    remenhuati:'熱門話題',
    news1:'原創',
    news2:'推薦',
    news3:'財富',
    renliulan:'人瀏覽',
    hezhi:'合計値',
    lunshu:'NO.',
    
    做多:'INR',
    做空:'KRW',
    多单:'CAD',
    多双:'AUD',
    空单:'USD',
    空双:'UER',
    平单:'SGD',
    平双:'GBP',
    极阴:'JPY',
    极阳:'VND',
    qi:'期',
    juli:'NO.',
    lunjiezhi:'輪截至',
    yifengpan:'閉まっている',
    yiguanbi:'閉まっている',
    yonghu:'ユーザー',
    danlunjiaoyi:'貿易',
    caozuo:'操作する',
    pljoin:'加入聊天室成功',
    pltingshou:'製品は製造中止となりました',
    gengou:"従う",
	quedinggengou:"購入をフォローしてもよろしいですか?",
	wanjia:"プレーヤー",
	leibie:"カテゴリー",
	jine:"額",
    gouru:'購入',
    zanweigouru:'アイテムを選択し、ここをクリックしてご購入ください',
    qigou:'最低購入金額',
    gourushuliang:'購入数量',
    changgui:'常規',
    shuzi:'數位',
    yinglihezhi:'利益と価値',
    shouyijieshao:'收益介紹',
    lijicunru:'今すぐ入金',
    kaihaojieguo:'結果',
    kaihaoqushi:'傾向',
    chanpingshuoming:'製品説明',
    qingchuhuancun:'更新する',
    youke:'游客',
	gongxi:'恭喜 ',
	zai:' 在 ',
	yingli:' 盈利 ',
	xiadanchenggong:'正常に注文されました',
	jiazaizhong:'読み込み中...',
	guanbitg:'閉店、購入停止',
	xiangoudl:'現在の購入ラウンドのみをフォローできます',
	liaotiantip:'システムにより禁止されています。購入することを選択してください',
	tishi:'ヒント',
	zhidao:'知っていた',
	zhuanchusb:'転送に失敗しました',
	zhuanchusbs:'残高不足のため送金できませんでした',
	buyxq:'購入詳細',
	orderNo:'請求書番号',
	plan_name:'お部屋のご案内',
	buyxx:'購入情報',
	haoxx:'番号選択の詳細',
	buysj:'購入時間',
	yilou:'省略',
	fzjqb:'ペーストボードにコピーされました',
	chanpin1:'販売開始時間：3分半に1周、当日午前10時開店、翌日午前2時閉店',
	chanpin2:'各ラウンドには 0 から 9 までの 3 つの数字があり、その 3 つの数字が最終結果に追加され、投資結果はロング、ショート、フラット、シングル、フラット、バイポーラ、アノード、カソードと数字になります。',
	Tips:{
        wjmm:'パスワードを忘れた場合は、カスタマー サービスに連絡して変更してください。',
        dlcg:'ログイン成功',
        tjcg:'正常に送信されました',
        zanwu:'データなし',
        buyizhi:'2 つのパスワードが矛盾しています',
        zcbz:'資産が不足していますので入金してください',
        pltxje:'出金額を入力してください',
        zdtx:'最低出金額 100',
		youke:'ご来場の皆様へ 正式会員登録を行ってからお進みください。',
		szcg:'セットアップに成功',
    },
	smrz:'實名認證',
	smrzzm:'身份認證正面',
	smrzxm:'姓名',
	smrzhm:'身份證號碼',
	smrztip:'提交成功,等待審核!',
	qwszl:'請完善資料',
	kfa:'カスタマーサービスコンサルタント',
	kfb:'カスタマーサービスコンサルタント',
	kfc:'カスタマーサービスコンサルタント',
	
	aboutaa:'SENTOFIVE は 2012 年 2 月に UBS と HSBC によって設立されました。 創立以来、本社の決定と取り決めを誠実に実行し、「市場志向、総合的、国際的、コンソーシアムベースの企業」の発展目標に焦点を当て、発展を揺るぎなく最優先事項として捉え、企業の発展、金融市場への奉仕、経営革新において多大な功績をあげ、好成績を収めました。',
	aboutab:'2023年末までに、グループは営業利益359億5,000万米ドル、総利益27億9,000万米ドル、純利益21億4,000万米ドル、総資産932億3,000万米ドル、純資産328億6,000万米ドルを達成しました。国際信用格付けおよび S&P AAA 国際信用格付けの見通し 安定化。',
	
	aboutac:'今後、当グループは2つの変革方向、4つの発展目標、4つの事業分野、8つの路線レイアウト、8つのコア機能からなる「248」発展戦略をしっかりと実行していく。 それは、本社制御、効率的な意思決定、チーム構築、人的効率管理、リスク監視、リスク予防、システムサポート、データガバナンスの8つの中核機能を構築し、変革と発展の新たな章を開く機会を捉えることだ。',
	
	aboutad:'未来を見据えて',
	xzyouxishuoming:'投資方向を選択してください',
	kefu:'カスタマーサービスコンサルタント',
	meiyouzhanghao:'アカウントがありません?',
	gongsijieshao:'導入',
	womenshishui:'私たちは誰ですか？',
	wfcw:'残高が不足しています。チャージしてください',
	ddcw:'プロジェクトエラー',
	dccw:'投資期間を間違えた',
	decw:'現在休業中',
	dfcw:'カスタマーサービスまでご連絡ください',
	dgcw:'購入に失敗しました',
	dhcw:'購入が成功しました',
	zdje:'取引金額',
	Invalidparameters:'無効なパラメーター',
	Loggedinsuccessful:'正常にサインインします',
	Logoutsuccessful:'正常に終了します',
	dicw:'編集することはできません。変更する必要がある場合は、カスタマー サービスにお問い合わせください。',
	//zIndex
	djcw:'新しいセキュリティコードを同じにすることはできません',
	dkcw:'暗証番号は6桁まで',
	dlcw:'古いパスワードが間違っています',
	dmcw:'セキュリティコードが正常に変更されました',
	dncw:'新しいセキュリティ コードを古いセキュリティ コードと同じにすることはできません',
	docw:'暗証番号は6桁まで',
	dpcw:'古いパスワードが間違っています',
	dqcw:'セキュリティコードが正常に変更されました',
	drcw:'新しいパスワードは以前のパスワードと同じであってはなりません',
	dscw:'20桁まで',
	dtcw:'編集に失敗する',
	ducw:'パスワードを再設定する',
	dvcw:'The guest account has no permissions. Please register as an official account first.',
	dwcw:'Sự cân bằng không chỉ',	
	dxcw:'間違ったパスワード',	
	dycw:'使い切らなければならない',	
	dzcw:'未解決のリクエストがあります',	
	eacw:'出金情報が間違っています',	
	ebcw:'出金に失敗しました',	
	eccw:'出金に失敗しました',	
	edcw:'送信されました。処理をお待ちください',	
	Uploadedsuccessful:'アップロード成功',	
	Chunkfiledisabled:'ファイルが無効になっています',
	qingshuruchakanmaTs:'問い合わせパスワードを入力してください。お忘れの場合は、カスタマーサービスまでご連絡ください。',
	anquanmaTpsnew:'カードを表示するにはパスワードを入力してください',
	anquanmaTpsnewtwo:'パスワードは安全に保管してください。 パスワードを忘れた場合は、カスタマーサービスまでご連絡ください。',
}
export default tw;