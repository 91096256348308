<template>
	<div class="language">
		<div class="settingwarp">
			<header>

				<div class="g-header">
					<div class="left" @click="changego">
						<van-icon name="arrow-left" color="#aaa" size="18px" />
					</div>
					<div class="middle fs-18">{{ $t("yuyanshezhi") }}</div>
					<div class="right">
						<div class="bg-icon bg-icon-setting"></div>
					</div>
				</div>

				<div class="blockHeight"></div>

				<div class="g-content">
					<ul class="lang-box">
						<li class="lang" :class="{ active: show == 0 }" @click="(show = 0), languageSet(0)">
							<span>{{ $t("gensuixitong") }}</span>
							<img src="@/assets/image/gou.png" class="icon" alt="" v-if="show == 0" />
						</li>
						<li class="lang" :class="{ active: show == 1 }" @click="(show = 1), languageSet(1)">
							<span>Asia｜Thailand</span>
							<img src="@/assets/image/gou.png" class="icon" alt="" v-if="show == 1" />
						</li>
						<li class="lang" :class="{ active: show == 2 }" @click="(show = 2), languageSet(2)">
							<span>America｜United States</span>
							<img src="@/assets/image/gou.png" class="icon" alt="" v-if="show == 2" />
						</li>
						<!-- <li class="lang" :class="{ active: show == 6 }" @click="(show = 6), languageSet(6)">
							<span>Deutsch</span>
							<img src="@/assets/image/gou.png" class="icon" alt="" v-if="show == 6" />
						</li>
						<li class="lang" :class="{ active: show == 3 }" @click="(show = 3), languageSet(3)">
							<span>แบบไทย</span>
							<img src="@/assets/image/gou.png" class="icon" alt="" v-if="show == 3" />
						</li>
						<li class="lang" :class="{ active: show == 4 }" @click="(show = 4), languageSet(4)">
							<span>한국인</span>
							<img src="@/assets/image/gou.png" class="icon" alt="" v-if="show == 4" />
						</li>
						<li class="lang" :class="{ active: show == 5 }" @click="(show = 5), languageSet(5)">
							<span>日本語</span>
							<img src="@/assets/image/gou.png" class="icon" alt="" v-if="show == 5" />
						</li> -->
						<!-- <li class="lang" :class="{ active: show == 7 }" @click="(show = 7), languageSet(7)">
							<span>Español</span>
							<img src="@/assets/image/gou.png" class="icon" alt="" v-if="show == 7" />
						</li> -->
						<!-- <li class="lang" :class="{ active: show == 8 }" @click="(show = 8), languageSet(8)">
							<span>Português</span>
							<img src="@/assets/image/gou.png" class="icon" alt="" v-if="show == 8" />
						</li> -->
						<!-- <li class="lang" :class="{ active: show == 9 }" @click="(show = 9), languageSet(9)">
							<span>繁體中文</span>
							<img src="@/assets/image/gou.png" class="icon" alt="" v-if="show == 9" />
						</li> -->
						<!-- <li
              class="lang"
              :class="{ active: show == 2 }"
              @click="(show = 2), languageSet(2)"
            >
              <span>中文繁体</span>
              <img
                src="@/assets/image/gou.png"
                class="icon"
                alt=""
                v-if="show == 2"
              />
            </li> -->
					</ul>
				</div>
			</header>
		</div>
	</div>
</template>
<script>
	export default {
		name: "Language",
		components: {},
		data() {
			return {
				checked: false,
				show: 0,
			};
		},
		methods: {
			changego() {
				this.$router.go(-1);
			},
			languageSet(type) {
				if (type == 0) {
					localStorage.setItem("locale", "th");
					localStorage.setItem("localetype", type);
					this.show = 0;
				} else if (type == 1) {
					localStorage.setItem("locale", "th");
					localStorage.setItem("localetype", type);
					this.show = 1;
				} else if (type == 2) {
					localStorage.setItem("locale", "en");
					localStorage.setItem("localetype", type);
					this.show = 2;
				// } else if (type == 3) {
				// 	localStorage.setItem("locale", "th");
				// 	localStorage.setItem("localetype", type);
				// 	this.show = 3;
				// } else if (type == 4) {
				// 	localStorage.setItem("locale", "kr");
				// 	localStorage.setItem("localetype", type);
				// 	this.show = 4;
				// } else if (type == 5) {
				// 	localStorage.setItem("locale", "jp");
				// 	localStorage.setItem("localetype", type);
				// 	this.show = 5;
				// } else if (type == 6) {
				// 	localStorage.setItem("locale", "de");
				// 	localStorage.setItem("localetype", type);
				// 	this.show = 6;
				// } else if (type == 7) {
				// 	localStorage.setItem("locale", "es");
				// 	localStorage.setItem("localetype", type);
				// 	this.show = 7;
				// } else if (type == 8) {
				// 	localStorage.setItem("locale", "po");
				// 	localStorage.setItem("localetype", type);
				// 	this.show = 8;
				// } else if (type == 9) {
				// 	localStorage.setItem("locale", "hk");
				// 	localStorage.setItem("localetype", type);
				// 	this.show = 9;
				}
				location.reload();
			},
		},
		created() {
			if (localStorage.getItem("localetype") == 1) {
				this.show = 1;
			} else if (localStorage.getItem("localetype") == 2) {
				this.show = 2;
			// } else if (localStorage.getItem("localetype") == 3) {
			// 	this.show = 3;
			// } else if (localStorage.getItem("localetype") == 4) {
			// 	this.show = 4;
			// } else if (localStorage.getItem("localetype") == 5) {
			// 	this.show = 5;
			// } else if (localStorage.getItem("localetype") == 6) {
			// 	this.show = 6;
			// } else if (localStorage.getItem("localetype") == 7) {
			// 	this.show = 7;
			// } else if (localStorage.getItem("localetype") == 8) {
			// 	this.show = 8;
			// } else if (localStorage.getItem("localetype") == 9) {
			// 	this.show = 9;
			} else [(this.show = 0)];
		},
	};
</script>
<style lang="less" scoped>
	.language {
		background: #fff;
	}

	.g-content {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		overflow: scroll;
		padding-top: 0;
		// background: #000;
		// margin-top: 1px;
	}


	.lang-box {
		padding: 0 0;
	}

	.lang {
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		color: #aaa;
		font-size: 0.32rem;
		border-bottom: .02rem solid #ececec;
		padding: 12px .3rem;
	}

	.active {
		color: #E31837;
		font-family: 'nstb';
	}
</style>