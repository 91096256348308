<template>
	<div class="addVirtualWallet">
		<div class="settingwarp">
			<header>
				<div class="g-header">
					<div class="left" @click="changego">
						<van-icon name="arrow-left" color="#fff" size="20px" />
					</div>
					<div class="middle fs-18">{{$t('smrz')}}</div>
					<div class="right">
						<div class="bg-icon bg-icon-setting"></div>
					</div>
				</div>
				<div class="blockHeight"></div>
			</header>
			<div class="g-content">
				<!-- 第一次设置安全码显示这个 -->
				<div class="withdraw-count">
					<div class="title">{{ $t("smrzxm") }}</div>
					<van-field v-model="user.name" type="text">
					</van-field>
				</div>
				<div class="withdraw-count">
					<div class="title">{{ $t("smrzhm") }}</div>
					<van-field v-model="user.idcard" type="text">
					</van-field>
				</div>
				<div class="withdraw-count">
					<div class="title">{{ $t("smrzzm") }}</div>
					<div style="width: 100%; margin-top: 10px;">
						<van-uploader :after-read="afterRead" result-type="text" v-if="!user.simg" />
						<img v-else :src="user.simg" alt="" />
					</div>
				</div>
				<div v-if="user.r_status == 0">
					<div style="width: calc(100% - 0.8rem); margin: 0 auto;">
						<van-button type="primary" block class="Btnsub"
							@click="changeSubmit">{{ $t("queren") }}</van-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import userApi from "@/api/user";
	export default {
		name: "AddVirtualWallet",
		components: {},
		data() {
			return {
				user: [],
			};
		},
		watch: {},
		methods: {
			changego() {
				this.$router.go(-1);
			},
			getUserInfo() {
				userApi.userInfo().then((data) => {
					console.log(data.msg);
					this.user = data.msg;
				});
			},
			afterRead(file) {
				let files = new FormData();
				files.append("file", file.file);
				window.$uploadAxios('/api/index/upload', files, (res) => {
					if (res.code == 1) {
						this.user.simg = res.data.fullurl;
					} else {
						this.$toast(this.$t(res.msg));
					}
				});
			},
			changeSubmit() {
				if (!this.user.idcard || !this.user.name || !this.user.simg) this.$toast.fail(this.$t('qwszl'));
				userApi
					.profile(this.user.idcard, this.user.name, this.user.simg)
					.then((data) => {
						if (data.code == 1) {
							this.$toast(this.$t('smrztip'));
						}
					})
					.catch((err) => {
						this.$toast.clear();
						this.$toast.fail(err.msg);
					});
			}
		},
		mounted() {},
		created() {
			this.getUserInfo();
		},
	};
</script>
<style lang="less" scoped>
	.settingwarp {
		background-image: url("../assets/image/manage_bg.jpg");
		background-size: 100% auto;
		background-position: top center;
		background-repeat: no-repeat;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	
	.g-content {
		width: 100%;
		background-color: #fff;
		border-radius: 15px 15px 0 0;
		padding-top: 0.4rem;
	}

	.lock-bg {
		position: relative;
		text-align: center;
		width: 9rem;
		height: 6rem;
		margin: 0 auto;
		margin-top: 1.8rem;
		background: url(../assets/image/anquanma.png) no-repeat center center;
		background-size: 100% 100%;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
		padding: 0.6rem 0.2rem;
	}

	.paycode {
		margin-top: 0.2rem;
	}

	.van-number-keyboard {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 100;
		width: 100%;
		padding-bottom: constant(safe-area-inset-bottom);
		padding-bottom: env(safe-area-inset-bottom);
		background-color: #1a243f;
		-webkit-user-select: none;
		user-select: none;
	}

	.tips {
		width: 8.4rem;
		display: flex;
		margin: 0.4rem auto 1rem;
		align-items: flex-start;
		font-size: 0.35rem;

		img {
			width: 0.4rem;
			margin-right: 0.12rem;
		}
	}

	.c_a0a0a0 {
		color: #a0a0a0;
	}

	.addtitle {
		color: #fff;
	}

	.van-cell::after {
		position: absolute;
		box-sizing: border-box;
		content: " ";
		pointer-events: none;
		bottom: 0;
		border-bottom: 1px solid #3d5179;
		-webkit-transform: scaleY(0.5);
		transform: scaleY(0.5);
	}

	.finish-btn {
		margin-top: auto;
		margin-bottom: 0.4rem;
		padding: 0.2rem;
	}

	.Btnlogin {
		color: #fff;
		background-color: #28539c;
	}

	.Btnsub {
		color: rgb(255, 255, 255);
		background: #28539c;
		margin: 20px auto 0 auto;
		border-radius: 15px;
		height: 35px;
		line-height: 35px; 
		border: none;
	}

	.withdraw-count {
		width: calc(100% - 0.8rem);
		margin: 0 auto 0 auto;
		padding: 0.1rem 0;

		img {
			width: 100%;
			height: 220px;
		}

		.title {
			text-align: left;
			color: #999;
			font-size: 0.32rem;
		}
	}
    
	/deep/ .van-cell{width: 100%; padding:10px 0;}
	/deep/ .van-field__control {
		border: 1px solid #f0f0f0;
		height: 40px;
		border-radius: 20px;
		margin: 0;
		color: #000;
		padding: 0 0.2rem;
	}
</style>