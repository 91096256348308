const tw={
	qfh:'THB',
    jiantizhongwen:'繁體中文',
    xianlu:'線路',
    dianwei:'當前點位',
    wodezichan:'我的資產',
    touzikuaixun:'投資資訊',
    jiaoyiyonghu:'交易用戶',
    jiaoyipinglei:'交易品類',
    danlunhuoli:'單輪獲利',
    jiaoyiriqi:'交易日期',
    dengluhouchakan:'登陸後查看',
    shouye:'首頁',
    jiaoyidating:'交易大廳',
    yucun:'預存',
    zoushi:'走勢',
    wode:'我的',
    zuiditouzi:'最低投資',
    zuidijinru:'最低進入',
    jichulicai:'基礎理財',
    zishenlicai:'資深理財',
    dashilicai:'大師理財',
    zhuanjialicai:'專家理財',
	基础理财:'基礎理財',
    资深理财:'資深理財',
    大师理财:'大師理財',
    专家理财:'專家理財',
    huiyuanquanyi:'會員權益',
    zuoriyingli:'昨日盈利',
    zongzichan:'總資產',
    chucunjin:'儲存金',
    jinriyingli:'今日盈利',
    zhuanchu:'轉出',
    cunru:'存入',
    zhanghuxinxni:'賬戶信息',
    lishizhangdan:'歷史賬單',
    zijinminxi:'資金明細',
    renwuzhongxin:'任務中心',
    xiaoxigonggao:'消息公告',
    tuijianfenxiang:'推薦分享',
    huodongzhongxin:'活動中心',
    jibenziliao:'基本資料',
    dengluzhanghao:'登錄賬號',
    nicheng:'昵称',
    shezhi:'設置',
    genggaixianlu:'更改線路',
    guanyuwomen:'關於我們',
    xitongshezhi:'系統設置',
    yuyanshezhi:'語言設置',
    tuichudenglu:'退出登錄',
    bangdingyinhangka:'綁定銀行卡',
    bangdingxuniqianbao:'綁定虛擬錢包',
    anquanma:'安全碼',
    xiugailenglumima:'修改登錄密碼',
    yinhangkaguanli:'銀行卡管理',
    tianjiayinhangka:'添加銀行卡',
    xuniqianbaoguanli:'虛擬錢包管理',
    tianjiaxuniqianbao:' 添加虛擬錢包',
    chiakren:'持卡人',
    yinhangkakahao:'銀行戶口號',
    yinhangmingcheng:'銀行名稱',
    yhkTps:'請綁定持卡人本人的銀行卡',
    nixiqianbaodizhi:'虛擬錢包地址',
    xuniqianbaoleixing:'虛擬錢包類型',
    xnqbTps:' 溫馨提示：請填寫USDT地址類型，如：trc20、erc20、omni。',
    plchikaren:'請輸入持卡人姓名',
    plkahao:'請輸入正確的銀行戶口號',
    plyinhangmingcheng:'請輸入銀行名稱',
    plqbdz:'請輸入虛擬錢包地址',
    plqblx:'請輸入虛擬錢包類型',
    shezhianquanma:'重置安全碼',
    yuananquanma:'原安全碼',
    xinanquanma:'新安全碼',
    planquanma:'請輸入原安全碼',
    plxinanquanma:'請輸入新安全碼',
    queren:'確認',
	quxiao:'取消',
    anquanmaTps:'請設置安全碼，請勿與銀行卡密碼相同',
    anquanmaTpsTow:'尊敬的客戶，此安全碼是您轉出時的密碼，為了安全起見，請盡量不要與登錄密碼相同',
    xiugaidenglu:'修改登錄密碼',
    jiumima:'舊密碼',
    xinmima:'新密碼',
    querenmima:'確認密碼',
    pljiumima:'請輸入舊密碼',
    plxinmima:'請輸入新密碼',
    plquerenmima:'請再次輸入密碼',
    wancheng:'完成',
    kaishitouzi:'開始投資',
    kaiTps:'當前線路無法使用時，可嘗試切換其他線路',
    zuiyou:'最優',
    dangaqianxianlu:'當前線路',
    dangqianbanben:'當前版本',
    banquansuoyou:'版權所有',
    yinglizhendong:'盈利震動',
    gensuixitong:'跟隨系統',
    zhuanchujine:'轉出金額',
    plzhuanchu:'請輸入轉出金額',
    zhuanchuTps:'如有疑問請聯系客服',
    xuanzeyh:'請選擇提款銀行卡',
    xuanzehb:'請選擇提款虛擬錢包',
    tijiaoshenqing:'提交申請',
    shuruanquanma:'請輸入安全碼',
    xianshangcunru:'線上存入',
    saomacunru:'USDT',
    wangyincunru:"網銀存入",
    shijian:'時間',
    upshijian:'開始時間',
    downshijian:'結束時間',
    wuTps:'暫無相關數據~',
    jiazaicx:'重新加載',
    di:'第',
    lun:'輪',
    weiyingli:'未盈利',
    yishouli:'已受理',
    zhuangtai:'狀態',
    chongzhi:'重置',
    riqi:'日期',
    CapActive:{
        chongzhi:'充值',
        tixian:'提現',
        goumai:'购买',
        yingli:'購買',
        zengsong:'贈送',
        weitongguo:'未通過',
        yitongguo:'已通過',
        yijujue:'已拒絕',
    },
    zhanneixiaoxi:"站内消息",
    pingtaigonggao:'平臺公告',
    fuzhilanjie:"復制鏈接",
    denglu:'登錄',
    jizhumima:'記住密碼',
    wangjimima:'忘記密碼',
    youkedenglu:'遊客登錄',
    zhucezhanghao:"立即註冊",
    plusername:'請輸入用戶名',
    plpassword:'請輸入密碼',
    wanchengzhuce:'完成註冊',
    yiyouzhanghao:'已有賬戶',
    qingdenglu:'請登錄',
    zhuce:'注册',
    remenhuati:'熱門話題',
    news1:'原創',
    news2:'推薦',
    news3:'財富',
    renliulan:'人瀏覽',
    hezhi:'和值',
    lunshu:'輪數',
    
    做多:'做多',
    做空:'做空',
    多单:'多單',
    多双:'多雙',
    空单:'空單',
    空双:'空雙',
    平单:'極陰',
    平双:'極陰',
	极阴:'極陰',
	极阳:'極陽',
    qi:'期',
    juli:'距離',
    lunjiezhi:'輪截至',
    yifengpan:'已封盤',
    yiguanbi:'已關閉',
    yonghu:'用戶',
    danlunjiaoyi:'單輪交易',
    caozuo:'操作',
    pljoin:'加入聊天室成功',
    pltingshou:'產品已停售',
    gengou:"跟購",
	quedinggengou:"確定跟購嗎",
	wanjia:"玩家",
	leibie:"類別",
	jine:"金額",
    gouru:'購入',
    zanweigouru:'請選擇項目並點此購入',
    qigou:'起購',
    gourushuliang:'購入數量',
    changgui:'常規',
    shuzi:'數位',
    yinglihezhi:'盈利和值',
    shouyijieshao:'收益介紹',
    lijicunru:'立即存入',
    kaihaojieguo:'開號結果',
    kaihaoqushi:'開號趨勢',
    chanpingshuoming:'產品說明',
    qingchuhuancun:'清除緩存',
    youke:'游客',
	gongxi:'恭喜 ',
	zai:' 在 ',
	yingli:' 盈利 ',
	xiadanchenggong:'下單成功',
	jiazaizhong:'加載中...',
	guanbitg:'已關閉，停止購入',
	xiangoudl:'只能跟購當前輪',
	liaotiantip:'系統已禁言，請選擇購入',
	tishi:'提示',
	zhidao:'知道了',
	zhuanchusb:'轉出失敗',
	zhuanchusbs:'餘額不足，轉出失敗',
	buyxq:'購入詳情',
	orderNo:'賬單編號',
	plan_name:'廳房信息',
	buyxx:'購入信息',
	haoxx:'選號詳情',
	buysj:'購入時間',
	yilou:'遺漏',
	fzjqb:'已復製到粘貼板',
	chanpin1:'開售時間：每3.5分鐘為一輪，當日10:00AM開盤，次日02:00AM停盤。 ',
	chanpin2:'每輪有3個0-9的數字，3個數字相加為最終結果，可以投資結果做多做空平單平雙極陽極陰以及數字。 ',
	Tips:{
        wjmm:'忘記密碼請聯繫客服修改!',
        dlcg:'登錄成功',
        tjcg:'提交成功',
        zanwu:'暫無數據',
        buyizhi:'兩次密碼不一致',
        zcbz:'您的資產不足請存入',
        pltxje:'請輸入提現金額',
        zdtx:'最低提現100',
		youke:'尊敬的遊客您好：請您註冊正式會員後操作。 ',
		szcg:'設置成功',
    },
	smrz:'實名認證',
	smrzzm:'身份認證正面',
	smrzxm:'姓名',
	smrzhm:'身份證號碼',
	smrztip:'提交成功,等待審核!',
	qwszl:'請完善資料',
    kfa:'客戶服務 1',
    kfb:'客戶服務 2',
    kfc:'客戶服務 3',
    aboutaa:'全球市場領導者 自2001年以來，我們一直很自豪能夠將獨立交易者與全球貨幣市場的潛力聯繫起來。今天，我們繼續挑戰自己，為交易者提供成功所需的一切',
    xzyouxishuoming:'請選擇投資方向',
    kefu:'客戶服務',
	meiyouzhanghao:'沒有賬號 ?'
}
export default tw;