const en={
	qfh:'THB',
    jiantizhongwen:'America｜United States',
    xianlu:'Line ',
    dianwei:'Point',
    wodezichan:'My assets',
    touzikuaixun:'Investment News',
    jiaoyiyonghu:'User',
    jiaoyipinglei:'Category',
    danlunhuoli:'Profit',
    jiaoyiriqi:'Date',
    dengluhouchakan:'View after login',
    shouye:'Home',
    jiaoyidating:'Hall',
    yucun:'Prestore',
    zoushi:'Trend',
    wode:'Mine',
    zuiditouzi:'Minimum investment',
    zuidijinru:'Minimum entry',
    jichulicai:'Basic Financing',
    zishenlicai:'Senior Financing',
    dashilicai:'Master Financing',
    zhuanjialicai:'Expert Financing',
	基础理财:'Basic Financing',
    资深理财:'Senior Financing',
    大师理财:'Master Financing',
    专家理财:'Expert Financing',
    huiyuanquanyi:'U-id',
	huiyuanname:'U-name',
    zuoriyingli:'Profit yesterday',
    zongzichan:'Total assets',
    chucunjin:'Money',
    jinriyingli:'Profit today',
    zhuanchu:'Withdraw',
    cunru:'Deposit',
    zhanghuxinxni:'Account information',
    lishizhangdan:'Historical bills',
    zijinminxi:'Funding Details',
    renwuzhongxin:'Mission center',
    xiaoxigonggao:'News announcement',
    tuijianfenxiang:'Recommended to share',
    huodongzhongxin:'Activity Center',
    jibenziliao:'Basic information',
    dengluzhanghao:'Login account',
    nicheng:'Nick name',
    shezhi:'Settings',
    genggaixianlu:'Change line',
    guanyuwomen:'About us',
    xitongshezhi:'System settings',
    yuyanshezhi:'Language settings',
    tuichudenglu:'Logout',
    bangdingyinhangka:'Bind bank card',
    bangdingxuniqianbao:'Bind virtual wallet',
    anquanma:'Security code',
    xiugailenglumima:'Modify login password',
    yinhangkaguanli:'Bank card management',
    tianjiayinhangka:'Add a bank card',
    xuniqianbaoguanli:'Virtual wallet management',
    tianjiaxuniqianbao:'Add virtual wallet',
    chiakren:'Cardholder',
    yinhangkakahao:'Bank card number',
    yinhangmingcheng:'Bank name',
    yhkTps:'Please bind the cardholder own bank card',
    nixiqianbaodizhi:'Wallet address',
    xuniqianbaoleixing:'Wallet Type',
    xnqbTps:' Reminder: Please fill in the USDT address type, such as: trc20, erc20, omni. ',
    plchikaren:'Please enter cardholder',
    plkahao:'Please enter cardholder card number',
    plyinhangmingcheng:'Please enter bank name',
    plqbdz:'Please enter the virtual wallet address',
    plqblx:'Please enter virtual wallet type',
    shezhianquanma:'Set security code',
    yuananquanma:'Original security code',
    xinanquanma:'New security code',
    planquanma:'Please enter the original security code',
    plxinanquanma:'Please enter a new security code',
    queren:'Confirm',
	quxiao:'Cancel',
    anquanmaTps:' Please set a security code, not the same as the bank card password',
    anquanmaTpsTow:' Dear customer, this security code is the password when you transfer out. For security reasons, please try not to be the same as the login password',
    xiugaidenglu:'Modify login password',
    jiumima:'Old Password',
    xinmima:'New password',
    querenmima:'Confirm Password',
    pljiumima:'Please enter old password',
    plxinmima:'Please enter a new password',
    plquerenmima:'Please enter the password again',
    wancheng:'Complete',
    kaishitouzi:'Start investing',
    kaiTps:'When the current line is unavailable, try switching to other lines',
    zuiyou:'Optimal',
    dangaqianxianlu:'Current line',
    dangqianbanben:'Current version',
    banquansuoyou:'Copyright',
    yinglizhendong:'Earnings shock',
    gensuixitong:'Default',
    zhuanchujine:'Amount',
    plzhuanchu:'Please enter the transfer amount',
    zhuanchuTps:'Tips: If you have any questions, please contact the platform customer service.',
    xuanzeyh:'Please select a bank card for withdrawal',
    xuanzehb:'Please select a virtual wallet for withdrawal',
    tijiaoshenqing:'Submit application',
    shuruanquanma:'Please enter security code',
    xianshangcunru:'Online deposit',
    saomacunru:'USDT',
    wangyincunru:"Banking deposit",
    shijian:'Time',
    upshijian:'Starting time',
    downshijian:'End Time',
    wuTps:'No data ~',
    jiazaicx:'Reload',
    di:'Period ',
    lun:' ',
    weiyingli:'Unprofitable',
    yishouli:'Accepted',
    zhuangtai:'Status',
    chongzhi:'Reset',
    riqi:'Date',
    CapActive:{
        chongzhi:'Recharge',
        tixian:'Withdraw',
        goumai:'Buy',
        yingli:'Profit',
        zengsong:'Give away',
        weitongguo:'Did not pass',
        yitongguo:'Passed',
        yijujue:'Rejected',
    },
    zhanneixiaoxi:"Information",
    pingtaigonggao:'Announcement',
    fuzhilanjie:"copy Link",
    denglu:'Login',
    zhuce:'Register',
    jizhumima:'Remember',
    wangjimima:'Forgot password',
    youkedenglu:'Visitor login',
    zhucezhanghao:"Register account",
    plusername:'Please enter user name',
    plpassword:'Please enter password',
    wanchengzhuce:'Complete',
    yiyouzhanghao:'Have an account',
    qingdenglu:'Please sign in',
    remenhuati:'Hot topic',
    news1:'Original',
    news2:'Recommend',
    news3:'Asset',
    renliulan:' browse',
    hezhi:'And value',
    lunshu:'Period',

    做多:'INR',
    做空:'KRW',
    多单:'CAD',
    多双:'AUD',
    空单:'USD',
    空双:'UER',
    平单:'SGD',
    平双:'GBP',
    极阴:'JPY',
    极阳:'THB',
    qi:'',
    juli:'Distance',
    lunjiezhi:'End',
    yifengpan:'Stop betting',
    yiguanbi:'Closed',
    yonghu:'User',
    danlunjiaoyi:'Trade',
    caozuo:'Operate',
    pljoin:'Join the chat room successfully',
    pltingshou:'Product has been discontinued',
    gengou:"Follow",
	quedinggengou:"Are you sure to follow the purchase?",
	wanjia:"player",
	leibie:"category",
	jine:"amount",
    gouru:'Buy',
    zanweigouru:'Please choose and click here to buy',
    qigou:' purchase',
    gourushuliang:'Purchase quantity',
    changgui:'Conventional',
    shuzi:'Number',
    yinglihezhi:'Profit and value',
    shouyijieshao:'Income',
    lijicunru:'Deposit',
    kaihaojieguo:'Record',
    kaihaoqushi:'Trend',
    chanpingshuoming:'Description',
    qingchuhuancun:'clear cache',
    youke:'Visitor',
	gongxi:'Congratulations to ',
	zai:' in the ',
	yingli:' profit ',
	xiadanchenggong:'successfully ordered',
	jiazaizhong:'Loading...',
	guanbitg:'closed, stop buying',
	xiangoudl:'Only follow the current round',
	liaotiantip:'The system has been banned, please choose to buy',
	tishi:'Tip',
	zhidao:'OK',
	zhuanchusb:'Failed to transfer out',
	zhuanchusbs:'Insufficient balance, transfer out failed',
	buyxq:'Purchase details',
	orderNo:'Bill No.',
	plan_name:'Hall information',
	buyxx:'Buy information',
	haoxx:'Number selection details',
	buysj:'Buy time',
	yilou: 'missing',
	fzjqb: 'Copied to clipboard',
	chanpin1: 'Sale time: every 3.5 minutes is a round, opening at 10:00AM on the same day and closing at 02:00AM the next day. ',
	chanpin2: 'There are 3 numbers from 0 to 9 in each round. The sum of the 3 numbers is the final result. You can invest in the result: Many, Short, Flat Single, Flat Double, Many Single, Many Double, Short Single, Short Double, Cathode, Anode, and number. ',
	Tips:{
        wjmm: 'Forgot your password, please contact customer service to modify it!',
        dlcg: 'Login successful',
        tjcg: 'Submitted successfully',
        zanwu: 'No data',
        buyizhi: 'The two passwords are inconsistent',
        zcbz: 'Your assets are insufficient, please deposit',
        pltxje: 'Please enter the withdrawal amount',
        zdtx: 'Minimum withdrawal 100',
		youke: 'Dear tourists: Please register as a full member and operate. ',
		szcg: 'set successfully',
     },
	smrz:'Real-name authentication',
	smrzzm: 'Authentication positive',
	smrzxm:'Name',
	smrzhm:'ID card number',
	smrztip: 'Successful submission, waiting for review!',
	qwszl: 'Please complete the information',
	kfa:'No.1 customer service',
	kfb:'No.2 customer service',
	kfc:'No.3 customer service',
	aboutaa:'Our presence in Thailand dates back to 1964, and we are now a leader among international investment banks in the Kingdom. Local operations are linked to the world’s major financial hubs by a global distribution network that provides in-depth, industry-specific expertise and regional market acumen.',
	aboutab:"We have operated in the Asia Pacific region for over 150 years, and today are present across 17 markets in the region. Clients rely on our global strength, local expertise and leadership across our lines of business.",
	aboutac:'In Asia Pacific, we are committed to helping promote economic growth and economic inclusion through our key philanthropic initiatives in the areas of workforce readiness, small business development and financial capability. Our investments aim to increase the number of quality jobs created for the underserved individuals and communities, helping small businesses become more sustainable, as well as assisting marginalized people in gaining access to affordable financial products so that they can become more financially secure. This shared commitment to the community drives our Foundation’s giving and employee engagement and volunteering activities across the region.',
	aboutad:"J.P. Morgan is a global leader in financial services, offering solutions to the world's most important corporations, governments and institutions in more than 100 countries. As announced in 2020, JPMorganChase will deploy $2 billion in philanthropic capital around the world by 2025. We also lead volunteer service activities for employees in local communities by utilizing our many resources, including those that stem from access to capital, economies of scale, global reach and expertise.",
	
	xzyouxishuoming:'Please select an investment direction',
	kefu:'Customer Service',
	meiyouzhanghao:'No Account ?',
	shifangshuaxin:'Pull down to refresh.',
	xialashuaxin:'Pull down to refresh.',
	tijiaoanniu: 'Send',
	
	womenshishui: 'Who are we?',
	wmjs: 'Local expertise. Global resources. Our commitment to Thailand',
	wmjsb: 'J.P. Morgan in Thailand',
	tohome: 'Go to home page',
	huancun: 'Refresh the page',/*刷新页面*/
	wfcw: 'The balance is insufficient. Please top up.',/*餘額不足，請儲值*/
	dccw: 'It is currently closed and unavailable for purchase.',/*目前已關閉且無法購買*/
	dzcw: 'If you have an order that has not yet been processed Please contact customer service.',/*有未处理订单，请联系客服*/
	dwcw: 'Insufficient balance for withdrawal', /*余额不足，无法提款*/
	dxcw: 'Fund password error',/*资金密码错误*/
	gongsijieshao: 'About',
	fk: 'Service',
	
	jiaoyidatingabc:'J.P.Morgan Trade',
	zengzhi:'Fund',
	'Parameter error':'Please fill in the investment amount',
	
	'可用余额':'Available Balance',
	'已存储金额':'Amount stored',
	'预计收益':'Estimated revenue',
	'投资日期':'Investment date',
	'开始计算利息':'Interest calculation time',
	'结算时间':'Settlement time',
	'自动复购':'Automatic repurchase',
	'储蓄金额':'Amount',
	'请输入储蓄金额':'Please enter the savings amount',
	'全部投入':'All',
	'购买':'Purchase items',
	
	'jjzhangdan':'Fund History',
	'待结算':'Awaiting settlement',
	
	'起购金额':"Minimum purchase amount",
}
export default en;