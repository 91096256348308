const tw={
	qfh:'THB',
    jiantizhongwen:'한국인',
    xianlu:'線路',
    dianwei:'색인',
    wodezichan:'내 자산',
    touzikuaixun:'投資資訊',
    jiaoyiyonghu:'거래 사용자',
    jiaoyipinglei:'범주',
    danlunhuoli:'이익',
    jiaoyiriqi:'날짜',
    dengluhouchakan:'登陸後查看',
    shouye:'첫 장',
    jiaoyidating:'거래소',
    yucun:'사전 저장',
    zoushi:'경향',
    wode:'내 거',
    zuiditouzi:'최소 투자',
    zuidijinru:'최소 입장',
    jichulicai:'기본 재무관리',
    zishenlicai:'고위 재무 관리',
    dashilicai:'마스터 재무 관리',
    zhuanjialicai:'전문적인 재무관리',
	基础理财:'최소 입장',
    资深理财:'기본 재무관리',
    大师理财:'마스터 재무 관리',
    专家理财:'전문적인 재무관리',
    huiyuanquanyi:'회원 ID',
	huiyuanname:'회원 이름',
    zuoriyingli:'어제 수익',
    zongzichan:'총 자산',
    chucunjin:'儲存金',
    jinriyingli:'오늘의 수익',
    zhuanchu:'철회하다',
    cunru:'보증금',
    zhanghuxinxni:'계정 정보',
    lishizhangdan:'역사적 법안',
    zijinminxi:'기금 세부정보',
    renwuzhongxin:'선교 센터',
    xiaoxigonggao:'뉴스 발표',
    tuijianfenxiang:'공유 추천',
    huodongzhongxin:'활동 센터',
    jibenziliao:'기본 정보',
    dengluzhanghao:'로그인 계정',
    nicheng:'닉네임',
    shezhi:'설정',
    genggaixianlu:'更改線路',
    guanyuwomen:'회사 소개',
    xitongshezhi:'환경 설정',
    yuyanshezhi:'언어 설정',
    tuichudenglu:'로그아웃',
    bangdingyinhangka:'은행 카드 바인딩',
    bangdingxuniqianbao:'綁定虛擬錢包',
    anquanma:'보안 코드',
    xiugailenglumima:'로그인 비밀번호 변경',
    yinhangkaguanli:'은행 카드 관리',
    tianjiayinhangka:'은행 카드 추가',
    xuniqianbaoguanli:'虛擬錢包管理',
    tianjiaxuniqianbao:' 添加虛擬錢包',
    chiakren:'카드홀더',
    yinhangkakahao:'은행 계좌 번호',
    yinhangmingcheng:'은행 이름',
    yhkTps:'카드 소지자의 은행카드를 묶어주세요.',
    nixiqianbaodizhi:'虛擬錢包地址',
    xuniqianbaoleixing:'虛擬錢包類型',
    xnqbTps:' 溫馨提示：請填寫USDT地址類型，如：trc20、erc20、omni。',
    plchikaren:'카드 소유자 이름을 입력하세요',
    plkahao:'정확한 은행계좌번호를 입력해주세요',
    plyinhangmingcheng:'은행명을 입력해주세요',
    plqbdz:'請輸入虛擬錢包地址',
    plqblx:'請輸入虛擬錢包類型',
    shezhianquanma:'보안 코드 재설정',
    yuananquanma:'원래 보안 코드',
    xinanquanma:'새로운 보안 코드',
    planquanma:'원래 보안 코드를 입력하세요',
    plxinanquanma:'새로운 보안 코드를 입력해주세요',
    queren:'확인하다',
	quxiao:'취소',
    anquanmaTps:'은행 카드 비밀번호와 동일해서는 안되는 보안 코드를 설정하십시오.',
    anquanmaTpsTow:'고객님, 본 보안코드는 이체시 비밀번호로 사용되며, 보안상의 이유로 로그인 비밀번호와 동일하지 않도록 주의하시기 바랍니다.',
    xiugaidenglu:'로그인 비밀번호 변경',
    jiumima:'기존 비밀번호',
    xinmima:'새 비밀번호',
    querenmima:'비밀번호 확인',
    pljiumima:'이전 비밀번호를 입력해주세요',
    plxinmima:'새로운 비밀번호를 입력해주세요',
    plquerenmima:'비밀번호를 다시 입력해주세요',
    wancheng:'마치다',
    kaishitouzi:'투자 시작',
    kaiTps:'當前線路無法使用時，可嘗試切換其他線路',
    zuiyou:'最優',
    dangaqianxianlu:'當前線路',
    dangqianbanben:'當前版本',
    banquansuoyou:'版權所有',
    yinglizhendong:'어닝쇼크',
    gensuixitong:'시스템을 따르세요',
    zhuanchujine:'이체금액',
    plzhuanchu:'이체금액을 입력해주세요',
    zhuanchuTps:'질문이 있으시면 고객 서비스에 문의하십시오.',
    xuanzeyh:'출금 은행 카드를 선택하세요',
    xuanzehb:'請選擇提款虛擬錢包',
    tijiaoshenqing:'지원서 제출',
    shuruanquanma:'보안코드를 입력해주세요',
    xianshangcunru:'線上存入',
    saomacunru:'USDT',
    wangyincunru:"網銀存入",
    shijian:'시간',
    upshijian:'시작 시간',
    downshijian:'종료 시간',
    wuTps:'아직 관련자료가 없습니다~',
    jiazaicx:'새로고침',
    di:'NO.',
    lun:'輪',
    weiyingli:'수익성이 없음',
    yishouli:'수락됨',
    zhuangtai:'상태',
    chongzhi:'초기화',
    riqi:'날짜',
    CapActive:{
        chongzhi:'충전하다',
        tixian:'철회하다',
        goumai:'구입하다',
        yingli:'구입하다',
        zengsong:'포기하다',
        weitongguo:'통과하지 못함',
        yitongguo:'합격',
        yijujue:'거부됨',
    },
    zhanneixiaoxi:"站内消息",
    pingtaigonggao:'平臺公告',
    fuzhilanjie:"링크 복사",
    denglu:'로그인',
    jizhumima:'비밀번호 기억하기',
    wangjimima:'비밀번호를 잊어버려',
    youkedenglu:'遊客登錄',
    zhucezhanghao:"지금 등록하세요",
    plusername:'사용자 이름을 입력하세요',
    plpassword:'비밀번호를 입력 해주세요',
	plpasswordok:'비밀번호를 다시 입력해주세요',
    wanchengzhuce:'등록 완료',
    yiyouzhanghao:'이미 계정이 있습니다',
    qingdenglu:'로그인 해주세요',
    zhuce:'계정 등록',
    remenhuati:'熱門話題',
    news1:'原創',
    news2:'推薦',
    news3:'財富',
    renliulan:'人瀏覽',
    hezhi:'합계 값',
    lunshu:'NO.',
    
    做多:'INR',
    做空:'KRW',
    多单:'CAD',
    多双:'AUD',
    空单:'USD',
    空双:'UER',
    平单:'SGD',
    平双:'GBP',
    极阴:'JPY',
    极阳:'VND',
    qi:'期',
    juli:'NO.',
    lunjiezhi:'輪截至',
    yifengpan:'닫은',
    yiguanbi:'닫은',
    yonghu:'사용자',
    danlunjiaoyi:'거래',
    caozuo:'작동하다',
    pljoin:'加入聊天室成功',
    pltingshou:'제품이 단종되었습니다',
    gengou:"후속 구매",
	quedinggengou:"구매를 따르시겠습니까?",
	wanjia:"플레이어",
	leibie:"범주",
	jine:"양",
    gouru:'구매',
    zanweigouru:'항목을 선택하고 여기를 클릭하여 구매하세요.',
    qigou:'최소 구매',
    gourushuliang:'구매 수량',
    changgui:'常規',
    shuzi:'數位',
    yinglihezhi:'盈利和值',
    shouyijieshao:'收益介紹',
    lijicunru:'지금 입금하세요',
    kaihaojieguo:'번호 공개 결과',
    kaihaoqushi:'오프닝 트렌드',
    chanpingshuoming:'제품 설명',
    qingchuhuancun:'캐시 지우기',
    youke:'游客',
	gongxi:'恭喜 ',
	zai:' 在 ',
	yingli:' 盈利 ',
	xiadanchenggong:'성공적으로 주문했습니다',
	jiazaizhong:'로드 중',
	guanbitg:'휴무, 구매중단',
	xiangoudl:'현재 구매 라운드만 팔로우할 수 있습니다.',
	liaotiantip:'系統已禁言，請選擇購入',
	tishi:'힌트',
	zhidao:'알고 있었다',
	zhuanchusb:'전송 실패',
	zhuanchusbs:'잔액 부족, 이체 실패',
	buyxq:'구매내역',
	orderNo:'청구서 번호',
	plan_name:'객실정보',
	buyxx:'구매정보',
	haoxx:'번호 선택 세부정보',
	buysj:'구매 시간',
	yilou:'생략',
	fzjqb:'임시보드에 복사됨',
	chanpin1:'판매개시시간 : 3.5분 간격으로 1회전, 당일 오전 10시에 개장하고 다음날 오전 2시에 마감합니다.',
	chanpin2:'각 라운드에는 0부터 9까지 세 개의 숫자가 있으며 세 개의 숫자가 최종 결과에 추가됩니다. 투자 결과는 롱, 숏, 플랫, 싱글, 플랫, 바이폴라, 양극, 음극 및 숫자가 될 수 있습니다.',
	Tips:{
        wjmm:'비밀번호를 잊으셨다면 고객센터로 연락하셔서 비밀번호를 변경해주세요!',
        dlcg:'성공적 로그인',
        tjcg:'성공적으로 제출되었습니다',
        zanwu:'데이터 없음',
        buyizhi:'두 개의 비밀번호가 일치하지 않습니다',
        zcbz:'자산이 부족합니다. 입금해주세요',
        pltxje:'출금금액을 입력해주세요',
        zdtx:'최소 출금 100',
		youke:'방문자 여러분, 진행하기 전에 정식 회원으로 등록하시기 바랍니다.',
		szcg:'설정 성공',
    },
	smrz:'實名認證',
	smrzzm:'身份認證正面',
	smrzxm:'姓名',
	smrzhm:'身份證號碼',
	smrztip:'提交成功,等待審核!',
	qwszl:'請完善資料',
	kfa:'고객 서비스 컨설턴트',
	kfb:'고객 서비스 컨설턴트',
	kfc:'고객 서비스 컨설턴트',
	
	aboutaa:'SENTOFIVE는 2012년 2월 UBS와 HSBC에 의해 설립되었습니다. 창립이래 "시장지향적, 종합적, 국제적, 컨소시엄 기반 기업"의 발전 목표에 초점을 맞춰 본사의 결정과 조치를 성실히 이행해 왔으며, 변함없이 발전을 최우선으로 삼아 왔으며, 기업발전, 금융시장 봉사, 경영혁신 분야에서 큰 성과를 거두었고 좋은 성과를 거두었습니다.',
	aboutab:'2023년 말까지 그룹은 영업이익 359억5000만달러, 총이익 27억9000만달러, 순이익 21억4000만달러, 총자산 932억3000만달러, 순자산 328억6000만달러를 잇달아 달성했다. 국제 신용 등급 및 S&P AAA 국제 신용 등급 Outlook Stablize.',
	
	aboutac:'앞으로 그룹은 2가지 변혁 방향, 4가지 개발 목표, 4가지 사업 부문, 8가지 선로 배치, 8가지 핵심 역량이라는 248 개발 전략을 확고히 실행할 예정이다. 본부통제, 효율적 의사결정, 팀빌딩, 인적효율성 관리, 리스크 모니터링, 리스크 예방, 시스템 지원, 데이터 거버넌스 등 8대 핵심역량을 구축하고 변화와 발전의 새로운 장을 여는 기회를 포착하는 것이다.',
	
	aboutad:'미래를 바라보며',
	xzyouxishuoming:'투자방향을 선택해 주세요',
	kefu:'고객 서비스 컨설턴트',
	meiyouzhanghao:'계정이 없나요?',
	gongsijieshao:'소개',
	womenshishui:'우리는 누구입니까?',
	wfcw:'잔액이 부족합니다. 충전해주세요.',
	ddcw:'프로젝트 오류',
	dccw:'잘못된 투자기간',
	decw:'현재 휴무',
	dfcw:'고객 서비스에 문의하십시오',
	dgcw:'구매 실패',
	dhcw:'구매 성공',
	zdje:'거래 금액',
	Invalidparameters:'잘못된 매개 변수',
	Loggedinsuccessful:'성공적으로 로그인하세요',
	Logoutsuccessful:'성공적으로 종료',
	dicw:'더 이상 수정이 불가능합니다. 수정이 필요한 경우 고객센터로 문의해주세요.',
	//zIndex
	djcw:'새 보안 코드는 동일할 수 없습니다.',
	dkcw:'보안 코드 최대 6자리',
	dlcw:'이전 비밀번호가 올바르지 않습니다.',
	dmcw:'보안 코드가 수정되었습니다.',
	dncw:'새 보안 코드는 이전 보안 코드와 동일할 수 없습니다.',
	docw:'보안 코드 최대 6자리',
	dpcw:'이전 비밀번호가 올바르지 않습니다.',
	dqcw:'보안 코드가 수정되었습니다.',
	drcw:'새 비밀번호는 이전 비밀번호와 달라야 합니다.',
	dscw:'최대 20자리',
	dtcw:'편집에 실패하다',
	ducw:'암호를 재설정',
	dvcw:'The guest account has no permissions. Please register as an official account first.',
	dwcw:'Sự cân bằng không chỉ',	
	dxcw:'잘못된 비밀번호',	
	dycw:'다 써버려야 해',	
	dzcw:'처리되지 않은 요청이 있습니다.',	
	eacw:'출금정보가 올바르지 않습니다.',	
	ebcw:'출금 실패',	
	eccw:'출금 실패',	
	edcw:'보냈습니다. 처리를 기다려 주세요.',	
	Uploadedsuccessful:'업로드 성공',	
	Chunkfiledisabled:'파일이 비활성화되었습니다.',
	qingshuruchakanmaTs:'문의 비밀번호를 입력해 주세요. 잊으셨을 경우 고객센터로 연락주세요.',
	anquanmaTpsnew:'카드를 보려면 비밀번호를 입력하세요',
	anquanmaTpsnewtwo:'비밀번호를 안전하게 보관하시기 바랍니다. 비밀번호를 잊어버린 경우 고객센터로 문의해주세요.',
}
export default tw;