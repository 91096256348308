import request from "@/utils/request";
export default {
  // 首页
  homeindex() {
    return request.get("/api/index/index");
  },
  // 交易接口
  Lotteryinfo(game_id) {
    return request.post("/api/Lottery/index", {
      game_id: game_id,
    });
  },
  //心跳
  heart(plan_id, game_id) {
    return request.post("/api/Lottery/heart", {
      plan_id: plan_id,
      game_id: game_id,
    });
  },
  //文章接口
  articleList() {
    return request.get("/api/index/articleList");
  },
  //文章接口
  oddsList(plan_id) {
    return request.post("/api/Lottery/oddsList", {
      plan_id: plan_id,
    });
  },
  //下注
  playOrder(issue, plan_id, game_id, play_type, money) {
    return request.post("/api/Lottery/playOrder", {
      issue: issue,
      plan_id: plan_id,
      game_id: game_id,
      play_type: play_type,
      money: money,
    });
  },
  issueList(game_id) {
    return request.post("/api/Lottery/issueList", {
      game_id: game_id,

    });
  },
  cand(game_id) {
    return request.post("/api/Lottery/cand", {
      game_id: game_id,

    });
  },
  //产品列表
  getProductlist() {
    return request.post("/api/Savingslist/getproductlist");
  },
  //资产详情
  assetsinfo() {
    return request.post("/api/Savingslist/getassetsinfo");
  },
  //添加订单
  addsaving(type,number,productid) {
    return request.post("/api/Savingslist/addsaving", {
		type: type,
		number: number,
		productid: productid,
    });
  },
  //储蓄订单
  getsavingslist(begin_date,end_date) {
    return request.post("/api/Savingslist/getsavingslist", {
		begin_date: begin_date,
		end_date: end_date
    });
  },
  //取消复购
  cancelfugou(ids) {
    return request.post("/api/Savingslist/cancelfugou", {
		id: ids,
    });
  },
};
