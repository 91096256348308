import { createI18n } from 'vue-i18n'
import th from './config/th'
import yn from './config/yn'
import en from './config/en'
import hk from './config/hk'
import kr from './config/kr'
import jp from './config/jp'
import de from './config/de'
import es from './config/es'
import po from './config/po'
console.log(createI18n)
const i18n  = createI18n({
    legacy: false, // composition API
    globalInjection:true,
    locale:localStorage.getItem('locale')||'th',
    messages:{
        th,
        yn,
		en,
		hk,
		kr,
		jp,
		de,
		es,
		po
    },
 })
 export default i18n;