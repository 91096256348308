const tw={
	qfh:'THB',
    jiantizhongwen:'繁體中文',
    xianlu:'線路',
    dianwei:'當前點位',
    wodezichan:'我的資產',
    touzikuaixun:'投資資訊',
    jiaoyiyonghu:'交易用戶',
    jiaoyipinglei:'交易品類',
    danlunhuoli:'單輪獲利',
    jiaoyiriqi:'交易日期',
    dengluhouchakan:'登陸後查看',
    shouye:'首頁',
    jiaoyidating:'交易',
    yucun:'預存',
    zoushi:'走勢',
    wode:'我的',
    zuiditouzi:'最低投資',
    zuidijinru:'最低進入',
    jichulicai:'基礎理財',
    zishenlicai:'資深理財',
    dashilicai:'大師理財',
    zhuanjialicai:'專家理財',
	基础理财:'基礎理財',
    资深理财:'資深理財',
    大师理财:'大師理財',
    专家理财:'專家理財',
    huiyuanquanyi:'會員ID',
	huiyuanname:'會員名稱',
    zuoriyingli:'昨日盈利',
    zongzichan:'總資產',
    chucunjin:'儲存金',
    jinriyingli:'今日盈利',
    zhuanchu:'轉出',
    cunru:'存入',
    zhanghuxinxni:'賬戶信息',
    lishizhangdan:'歷史賬單',
    zijinminxi:'資金明細',
    renwuzhongxin:'任務中心',
    xiaoxigonggao:'消息公告',
    tuijianfenxiang:'推薦分享',
    huodongzhongxin:'活動中心',
    jibenziliao:'基本資料',
    dengluzhanghao:'登錄賬號',
    nicheng:'昵称',
    shezhi:'設置',
    genggaixianlu:'更改線路',
    guanyuwomen:'關於我們',
    xitongshezhi:'系統設置',
    yuyanshezhi:'語言設置',
    tuichudenglu:'退出登錄',
    bangdingyinhangka:'綁定銀行卡',
    bangdingxuniqianbao:'綁定虛擬錢包',
    anquanma:'安全碼',
    xiugailenglumima:'修改登錄密碼',
    yinhangkaguanli:'銀行卡管理',
    tianjiayinhangka:'添加銀行卡',
    xuniqianbaoguanli:'虛擬錢包管理',
    tianjiaxuniqianbao:' 添加虛擬錢包',
    chiakren:'持卡人',
    yinhangkakahao:'銀行戶口號',
    yinhangmingcheng:'銀行名稱',
    yhkTps:'請綁定持卡人本人的銀行卡',
    nixiqianbaodizhi:'虛擬錢包地址',
    xuniqianbaoleixing:'虛擬錢包類型',
    xnqbTps:' 溫馨提示：請填寫USDT地址類型，如：trc20、erc20、omni。',
    plchikaren:'請輸入持卡人姓名',
    plkahao:'請輸入正確的銀行戶口號',
    plyinhangmingcheng:'請輸入銀行名稱',
    plqbdz:'請輸入虛擬錢包地址',
    plqblx:'請輸入虛擬錢包類型',
    shezhianquanma:'重置安全碼',
    yuananquanma:'原安全碼',
    xinanquanma:'新安全碼',
    planquanma:'請輸入原安全碼',
    plxinanquanma:'請輸入新安全碼',
    queren:'確認',
	quxiao:'取消',
    anquanmaTps:'請設置安全碼，請勿與銀行卡密碼相同',
    anquanmaTpsTow:'尊敬的客戶，此安全碼是您轉出時的密碼，為了安全起見，請盡量不要與登錄密碼相同',
    xiugaidenglu:'修改登錄密碼',
    jiumima:'舊密碼',
    xinmima:'新密碼',
    querenmima:'確認密碼',
    pljiumima:'請輸入舊密碼',
    plxinmima:'請輸入新密碼',
    plquerenmima:'請再次輸入密碼',
    wancheng:'完成',
    kaishitouzi:'開始投資',
    kaiTps:'當前線路無法使用時，可嘗試切換其他線路',
    zuiyou:'最優',
    dangaqianxianlu:'當前線路',
    dangqianbanben:'當前版本',
    banquansuoyou:'版權所有',
    yinglizhendong:'盈利震動',
    gensuixitong:'跟隨系統',
    zhuanchujine:'轉出金額',
    plzhuanchu:'請輸入轉出金額',
    zhuanchuTps:'如有疑問請聯系客服',
    xuanzeyh:'請選擇提款銀行卡',
    xuanzehb:'請選擇提款虛擬錢包',
    tijiaoshenqing:'提交申請',
    shuruanquanma:'請輸入安全碼',
    xianshangcunru:'線上存入',
    saomacunru:'USDT',
    wangyincunru:"網銀存入",
    shijian:'時間',
    upshijian:'開始時間',
    downshijian:'結束時間',
    wuTps:'暫無相關數據~',
    jiazaicx:'重新加載',
    di:'第',
    lun:'輪',
    weiyingli:'未盈利',
    yishouli:'已受理',
    zhuangtai:'狀態',
    chongzhi:'重置',
    riqi:'日期',
    CapActive:{
        chongzhi:'充值',
        tixian:'提現',
        goumai:'购买',
        yingli:'購買',
        zengsong:'贈送',
        weitongguo:'未通過',
        yitongguo:'已通過',
        yijujue:'已拒絕',
    },
    zhanneixiaoxi:"站内消息",
    pingtaigonggao:'平臺公告',
    fuzhilanjie:"復制鏈接",
    denglu:'登錄',
    jizhumima:'記住密碼',
    wangjimima:'忘記密碼',
    youkedenglu:'遊客登錄',
    zhucezhanghao:"立即註冊",
    plusername:'請輸入用戶名',
    plpassword:'請輸入密碼',
	plpasswordok:'請再次輸入密碼',
    wanchengzhuce:'完成註冊',
    yiyouzhanghao:'已有賬戶',
    qingdenglu:'請登錄',
    zhuce:'註冊帳戶',
    remenhuati:'熱門話題',
    news1:'原創',
    news2:'推薦',
    news3:'財富',
    renliulan:'人瀏覽',
    hezhi:'和值',
    lunshu:'輪數',
    
    做多:'INR',
    做空:'KRW',
    多单:'CAD',
    多双:'AUD',
    空单:'USD',
    空双:'UER',
    平单:'SGD',
    平双:'GBP',
    极阴:'JPY',
    极阳:'VND',
    qi:'期',
    juli:'NO.',
    lunjiezhi:'輪截至',
    yifengpan:'已封盤',
    yiguanbi:'已關閉',
    yonghu:'用戶',
    danlunjiaoyi:'單輪交易',
    caozuo:'操作',
    pljoin:'加入聊天室成功',
    pltingshou:'產品已停售',
    gengou:"跟購",
	quedinggengou:"確定跟購嗎",
	wanjia:"玩家",
	leibie:"類別",
	jine:"金額",
    gouru:'購入',
    zanweigouru:'請選擇項目並點此購入',
    qigou:'起購',
    gourushuliang:'購入數量',
    changgui:'常規',
    shuzi:'數位',
    yinglihezhi:'盈利和值',
    shouyijieshao:'收益介紹',
    lijicunru:'立即存入',
    kaihaojieguo:'開號結果',
    kaihaoqushi:'開號趨勢',
    chanpingshuoming:'產品說明',
    qingchuhuancun:'清除緩存',
    youke:'游客',
	gongxi:'恭喜 ',
	zai:' 在 ',
	yingli:' 盈利 ',
	xiadanchenggong:'下單成功',
	jiazaizhong:'加載中...',
	guanbitg:'已關閉，停止購入',
	xiangoudl:'只能跟購當前輪',
	liaotiantip:'系統已禁言，請選擇購入',
	tishi:'提示',
	zhidao:'知道了',
	zhuanchusb:'轉出失敗',
	zhuanchusbs:'餘額不足，轉出失敗',
	buyxq:'購入詳情',
	orderNo:'賬單編號',
	plan_name:'廳房信息',
	buyxx:'購入信息',
	haoxx:'選號詳情',
	buysj:'購入時間',
	yilou:'遺漏',
	fzjqb:'已復製到粘貼板',
	chanpin1:'開售時間：每3.5分鐘為一輪，當日10:00AM開盤，次日02:00AM停盤。 ',
	chanpin2:'每輪有3個0-9的數字，3個數字相加為最終結果，可以投資結果做多做空平單平雙極陽極陰以及數字。 ',
	Tips:{
        wjmm:'忘記密碼請聯繫客服修改!',
        dlcg:'登錄成功',
        tjcg:'提交成功',
        zanwu:'暫無數據',
        buyizhi:'兩次密碼不一致',
        zcbz:'您的資產不足請存入',
        pltxje:'請輸入提現金額',
        zdtx:'最低提現100',
		youke:'尊敬的遊客您好：請您註冊正式會員後操作。 ',
		szcg:'設置成功',
    },
	smrz:'實名認證',
	smrzzm:'身份認證正面',
	smrzxm:'姓名',
	smrzhm:'身份證號碼',
	smrztip:'提交成功,等待審核!',
	qwszl:'請完善資料',
	kfa:'客服顧問',
	kfb:'客服顧問',
	kfc:'客服顧問',
	
	aboutaa:'SENTOFIVE成立於2012年2月，由瑞士銀行和匯豐銀行共同組成。 組成以來，認真執行總部決策部署，圍繞「市場化、綜合化、國際化、財團型企業」發展目標，堅定不移把發展作為第一要務，在企業發展、服務金融市場、管理創新等方面取得 了良好成效。',
	aboutab:'截至2023年底，集團營業收入359.5億美元，利潤總額27.9億美元，淨利21.4億美元，總資產932.3億美元，淨資產328.6億美元，相繼獲得國際信用評級及標普AAA國際信用評級，展望 穩定。',
	
	aboutac:'未來，集團將堅定實施兩個轉型方向、四個發展目標、四個業務板塊、八個賽道佈局、八個核心能力的「二四八」發展策略。 即打造總部管控、高效決策，團隊建立、人效管理，風險監測、風險防範，系統支援、資料治理八大核心能力，乘勢而上開啟轉型發展新篇章。',
	
	aboutad:'展望未來',
	xzyouxishuoming:'請選擇投資方向',
	kefu:'客服顧問',
	meiyouzhanghao:'沒有帳號?',
	gongsijieshao:'簡介',
	womenshishui:'我們是誰？',
	wfcw:'您的餘額不足,請儲值',
	ddcw:'專案錯誤',
	dccw:'投資期數錯誤',
	decw:'目前已關閉',
	dfcw:'請聯絡客服',
	dgcw:'購買未成功',
	dhcw:'購買成功',
	zdje:'交易金額',
	Invalidparameters:'無效的參數',
	Loggedinsuccessful:'登入成功',
	Logoutsuccessful:'退出成功',
	dicw:'您無法再編輯它,如果您需要進行更改,請聯絡客服人員',
	//zIndex
	djcw:'新的安全碼不能相同',
	dkcw:'安全碼最多六位',
	dlcw:'舊密碼不正確',
	dmcw:'安全碼修改成功',
	dncw:'新安全碼不能与旧的相同',
	docw:'安全碼最多六位',
	dpcw:'舊密碼不正確',
	dqcw:'安全碼修改成功',
	drcw:'新密碼不得與先前的密碼相同',
	dscw:'最多 20 位數字',
	dtcw:'修改失敗',
	ducw:'重新設密碼',
	dvcw:'The guest account has no permissions. Please register as an official account first.',
	dwcw:'Sự cân bằng không chỉ',	
	dxcw:'密碼錯誤',	
	dycw:'必須使用完畢',	
	dzcw:'有尚未處理的請求',	
	eacw:'提款資料不正確',	
	ebcw:'提現失敗',	
	eccw:'提現失敗',	
	edcw:'已發送,請等待處理',	
	Uploadedsuccessful:'上傳成功',	
	Chunkfiledisabled:'檔案被禁用',
	qingshuruchakanmaTs:'請輸入查詢密碼，如果忘記，請聯絡客服。',
	anquanmaTpsnew:'請輸入您的密碼以查看銀行卡',
	anquanmaTpsnewtwo:'請妥善保管您的密碼。 如果您忘記密碼，請聯絡客服。',
}
export default tw;