<template>
  <div class="keyboard">
    <div @click.stop="hide" v-if="showKeyboard" class="bg"></div>

    <div ref="cusBoard" v-if="showKeyboard" class="cus-board">
      <div v-for="(line, index) in keys" :key="'line' + index" class="letter-line">
  
        <div v-if="index === keys.length - 1" @click.stop="hide" class="action">
			C
        </div>
        <div @click="touchStart" @click.stop="touchEnd" v-for="key in line" :key="key" :data-text="key" class="item">{{ key }}</div>

        <div v-if="index === keys.length - 1" @click.stop="handleDel" class="action">
			Del
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Keyboard",
  props: {
    text: {
      type: String,
      default: "1111",
    },
    defaultVal: {
      type: String,
      default: "",
    },
    length: {
      type: Number,
      default: 6,
    },
	showstatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keys: [
        [1, 2, 3],
        [4, 5, 6],
        [7, 8, 9],
        [0],
      ],
      showKeyboard: false,
      isFocus: false,
    };
  },
  computed: {

    currentInput: function() {
      
      const length = this.text.length || 0;
      return length;
    },
  },
  mounted() {
    this.$emit("update:text", this.defaultVal);
  },
  methods: {
    show() {
		this.isFocus = true;
		this.showKeyboard = true;
		
		setTimeout(() => {
			this.$emit("update:showstatus", this.showKeyboard);
			this.$refs.cusBoard.style.transform = `translateY(0)`;
		}, 20);
    },
    hide() {
      this.isFocus = false;
      setTimeout(() => {
        this.showKeyboard = false;
		this.$refs.cusBoard.style.transform = `translateY(100%)`;
      }, 200);
    },
    touchStart(el) {
		const { target } = el;
		let text = this.text;
		
		if (text.length >= this.length) return;
		const content = el.target.innerText;
		
		text += content;
		this.$emit("update:text", text);
		target.style.background = "rgb(228, 229, 228)";
    },
    touchEnd(el) {
      const { target } = el;
      setTimeout(() => {
        target.style.background = "#fff";
      }, 100);
    },
    handleDel() {
      if (this.defaultVal && this.text.length === this.defaultVal.length && this.text.indexOf(this.defaultVal) === 0) {
        return;
      }
      let text = this.text;
      text = text.slice(0, text.length - 1);
      this.$emit("update:text", text);
    },
  },
};
</script>

<style lang="less" scoped>
	.keyboard {
		user-select: none;
	}
	.bg {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		background: rgba(255, 255, 255, 0);
	}
.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.box-item {
  flex-basis: 35px;
  height: 40px;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    position: relative;
    border-color: #348fec;
    &::after {
      position: absolute;
      content: "";
      width: 1px;
      height: 50%;
      background-color: #333333;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      animation: inputFocusLine 1s infinite;
    }
  }
  &.hight-light {
    border-color: #348fec;
  }
}
@keyframes inputFocusLine {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.cus-board {
  font-size: 14px;
  width: 100%;
  background: rgb(246, 247, 246);
  padding: 15px 5px 30px 5px;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  transition: all 0.5s;
  .active {
    &::after {
      position: absolute;
      top: -40px;
      left: 0;
      width: 32px;
      height: 40px;
      background-color: #ffffff;
      content: attr(data-text);
      animation: itemActive 0.5s infinite;
    }
  }
}
.item,
.action {
  width: 30%;
  height: 45px;
  border-radius: 5px;
  background-color: white;
  line-height: 45px;
  text-align: center;
  position: relative;
  font-family: 'nstb';
  color: #999;
  img {
    display: inline-block;
    width: 16px;
    height: 16px;
  }
}
@keyframes itemActive {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
}
.letter-line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .item,
  .action {
    margin: 3px;
  }
}

</style>
