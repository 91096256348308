<template>
	<div class="withdraw">
		<div class="settingwarp">
			<header>
				<div class="g-header">
					<div class="left" @click="changego">
						<van-icon name="arrow-left" color="#aaa" size="18px" />
					</div>
					<div class="middle fs-18" @click="alertTypetitle = 2">
						{{ $t("zhuanchu") }}
					</div>
					<div class="right">
						<div class="btn-save fs-16"></div>
					</div>
				</div>
				<div class="blockHeight"></div>
				<div class="g-content flex-column">
					<div class="mescroll">
						<div>
							<div class="scroll-wrapper">
								<div class="balance">
									<div class="money-box">
										<p class="num" v-if="infoList">{{ infoList.money }}
											<!-- <font>{{ $t('qfh') }}</font> -->
										</p>
										<p>{{ $t("zongzichan") }}</p>
									</div>
								</div>
								<div class="withdraw-count">
									<div class="title">{{ $t("zhuanchujine") }}</div>
									<van-field v-model="money" type="number" :placeholder="$t('plzhuanchu')">
										<template #left-icon>
											<img src="@/assets/image/jinbi.png" class="left-form-icon" alt="" />
										</template>
									</van-field>
									<div class="tips">
										{{ $t("zhuanchuTps") }}
									</div>
								</div>
								<div class="pay-box">
									<!-- 虚拟钱包 -->
									<div class="pay-type">
										<div class="type-catalog">
											<div class="bank-title">{{ $t("xuanzeyh") }}</div>
										</div>
										<ul>
											<li>
												<div class="conetnt" @click="selectActive = 0" v-if="
                            infoList &&
                            infoList.pay_list &&
                            infoList.pay_list.length != 0 &&
                            infoList.pay_list[0]
                          ">
													<div class="title">
														<h2>{{formatPhone(infoList.pay_list[0].acc_name)}}<!-- {{ infoList.pay_list[0].acc_name }} -->
														</h2>
														<p class="fs-12">{{ infoList.pay_list[0].acc }} /
															{{infoList.pay_list[0].acc_bank}}</p>
													</div>
													<div class="radio">
														<label class="advice" :class="
                                selectActive == 0 ? 'advice-hl' : 'advice-nor'
                              "></label>
													</div>
												</div>
											</li>
										</ul>
									</div>
									<!-- <div class="pay-type">
										<div class="type-catalog">
											<div class="bank-title">{{ $t("xuanzehb") }}</div>
										</div>
										<ul>
											<li>
												<div class="conetnt" @click="selectActive = 0" v-if="
                            infoList &&
                            infoList.pay_list &&
                            infoList.pay_list.length != 0 &&
                            infoList.pay_list[1]
                          ">
													<div class="title">
														<h2 v-if="infoList">
															{{ infoList.pay_list[1].acc_bank }}
														</h2>
														<p class="fs-12">
															{{ infoList.pay_list[1].acc_name }}
														</p>
													</div>
													<div class="radio">
														<label class="advice" :class="
                                selectActive == 0 ? 'advice-hl' : 'advice-nor'
                              "></label>
													</div>
												</div>
											</li>
										</ul>
									</div> -->
								</div>
							</div>
						</div>
					</div>
					<!-- 提交 -->
					<van-button type="primary" block class="Btnsub"
						@click="changeSubmit">{{ $t("tijiaoshenqing") }}</van-button>
				</div>
			</header>
		</div>
		<alert-pop :alertType="alertType" :Tips="TipsTxt" v-if="PopAlert" @showAletfalse="showAletfalse"></alert-pop>
		<!-- 安全码弹出框提交申请的时候弹出 -->
		<van-popup v-model:show="showsecurity" class="securityalert">
			<div>
				<div class="safe-code">
					<div class="title ta-c c_fff">{{ $t("shuruanquanma") }}</div>
					<div class="paycode">
						<van-password-input :value="valuenum" :focused="showKeyboard"
							@focus="showKeyboard = true" />
					</div>
				</div>
			</div>
		</van-popup>
	
		<Keyboard ref="cusBoardss" :length="length"   :defaultVal="defaultVal" v-model:text="valuenum" v-model:showstatus="showstatus"></Keyboard>
	
	</div>
	
	<!-- 基本逻辑,1判断是否游客-2要完成基本资料-3如果没有银行卡就绑定银行卡,4未设置安全码设置安全码,5提交申请输入安全码 -->
</template>

<script>
	import AlertPop from "@/components/alert/AlertPop.vue";
	import userApi from "@/api/user";
	import Keyboard from "@/components/keyboard/index.vue";
	
	export default {
		name: "Withdraw",
		components: {
			AlertPop,
			Keyboard,
		},
		data() {
			return {
				selectActive: 0,
				showsecurity: false,
				showsecurityValue: "",
				alertType: null,
				infoList: [],
				isTrue: null,
				TipsTxt: this.$t('Tips.youke'),
				PopAlert: false,
				money: "",
				alertTypetitle: 2,
				valuenum: "",
				defaultVal: "",
				length: 6,
				showstatus: false,
			};
		},
		watch: {
			showsecurityValue(value) {
				if (value.length === 6) {
					// 调用接口
					this.showsecurity = false;
					this.Withdrawal();
				}
			},
			showsecurity(value){
				if (value == false){
					this.$nextTick(() => {
						//this.$refs.cusBoardss.hide();
						this.valuenum='';
						this.showstatus=false;
						this.showsecurity=false;
					});
				}
			},
			showstatus(value){
				if (value == false){
					this.showsecurity = false;
				}
			},
			valuenum(value){
				if (value.length === 6) {
					// 调用接口
					this.showsecurity = false;
					this.$nextTick(() => {
						this.$refs.cusBoardss.hide();
						this.valuenum='';
					});
					this.Withdrawal();
				}
			},
		},

		methods: {
			accept(text) {
				alert("Input text: " + text);
				this.hide();
			},
			
			formatPhone(phone) {
				return phone.substring(0, 6) + '××××'
			},
			showAletfalse() {
				this.PopAlert = false;
				this.$router.go(-1);
				
			},
			
			changeSubmit() {
				// 个人资料完整验证废除
				//判断是否游客
				// if (!this.usercrud) {
				// 	userApi.userInfo().then((data) => {
				// 		this.user = data.msg;
				// 		this.$router.push({
				// 			path: "/smrz"
				// 		});
				// 	});
				// 	return;
				// }
				if (this.infoList.pay_list.length==0) {

					// 是否有银行卡
					this.$router.push({
						path: "/bankCardManage",
						query: {
							type: 0,
						},
					});
				} else if (!this.isTrue) {
					// 是否有安全码
					this.$router.push({
						path: "/setSecurityCode",
						query: {
							isTrue: this.isTrue,
						},
					});
				} else {
					// 提现请求
					if (!this.money) {
						this.$toast(this.$t('Tips.pltxje'));
					} else if (this.money < 100) {
						this.$toast(this.$t('Tips.zdtx'));
					} else {
						this.showsecurity = true;
						this.$nextTick(() => {
							
							this.$refs.cusBoardss.show();
						});
					}
				}
			},
			Withdrawal() {
				this.$toast.loading({
					message: this.$t('jiazaizhong'),
					forbidClick: true,
					duration: 0,
				});
				userApi
					.withdrawAction(this.selectActive, this.money, this.valuenum)
					.then((data) => {
						console.log(data.data);
						this.infoList = data.data;
						this.$toast.clear();
						this.alertType = 3;
						this.TipsTxt = this.$t('Tips.tjcg');
						this.PopAlert = true;
						this.valuenum='';
					})
					.catch((err) => {
						this.$toast.clear();
						this.$toast.fail(this.$t(err.msg));
						this.valuenum='';
					});
			},
			ChangeAuth() {
				this.$router.push({
					path: "/defaultAvatar",
				});
			},
			changego() {
				this.$router.go(-1);
			},
			info() {
				this.$toast.loading({
					message: this.$t('jiazaizhong'),
					forbidClick: true,
					duration: 0,
				});
				userApi
					.withdrawInfo()
					.then((data) => {
						console.log(data.data);
						this.infoList = data.data;
						this.$toast.clear();
					})
					.catch((err) => {
						this.$toast.clear();
						this.$toast.fail(this.$t(err.msg));
					});
			},
		},
		computed: {
			group_id() {
				return this.$store.state.group_id;
			},
			usercrud() {
				console.log(this.$store.state.usercrud, "12");
				return this.$store.state.usercrud;
			},
		},
		created() {
			this.info();
			// this.group_id = this.$route.query.group_id;
			if (this.group_id != 1) {
				// 打开弹窗
				this.alertType = 1;
				this.PopAlert = true;
			}
			userApi.safetyInfo().then((data) => {
				this.isTrue = data.data.isTrue;
			});
		},
	};
</script>
<style lang="less" scoped>
	.settingwarp {
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.g-content {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		overflow: scroll;
		text-align: center;
		color: #aaa;
		background-color: #fff;
	}


	.mescroll {
		width: 100%;
		height: 100%;
		overflow-y: auto;
	}

	.balance {
		position: relative;
		height: 2.5rem;
		text-align: center;
		// background-color: #3d699f;
	}

	.money-box {
		color: #999;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-size: 0.32rem;
	}

	.num {
		font-size: 0.7rem;
		font-family: 'nstb';
		color: #000;

		font {
			font-size: .32rem;
		}
	}

	.withdraw-count {
		background-color: #f5f5f5;
		padding: 0.4rem 0;
		width: calc(100% - 0.6rem);
		margin: 0 auto;
		border-radius: 5px;

		.title {
			padding: 0 16px;
			text-align: left;
			color: #000;
			font-size: 0.36rem;
			font-family: 'nstb';
		}
	}

	.tips {
		color: #a0a0a0;
		margin-top: 0.3rem;
		font-size: 0.3rem;
		padding: 0 16px;
		white-space: pre-wrap;
		text-align: left;
	}

	.van-cell::after {
		position: absolute;
		box-sizing: border-box;
		content: " ";
		pointer-events: none;
		right: 16px;
		bottom: 0;
		left: 16px;
		border-bottom: .02rem solid #ddd;
		-webkit-transform: scaleY(0.5);
		transform: scaleY(0.5);
	}

	.left-form-icon {
		width: 20px;
		height: 20px;
		margin-right: 5px;
	}

	.pay-box {}

	.pay-type {}

	.type-catalog {
		height: 1.1rem;
		line-height: 1.1rem;
		position: relative;
	}

	// .type-catalog::before {
	// 	content: "";
	// 	display: block;
	// 	position: absolute;
	// 	width: 0.1rem;
	// 	height: 60%;
	// 	background-color: #4b77ff;
	// 	top: 50%;
	// 	-webkit-transform: translateY(-50%);
	// 	transform: translateY(-50%);
	// }

	.bank-title {
		padding-left: calc(0.2rem + 16px);
		text-align: left;
		color: #aaa;
		font-size: 0.36rem;
		font-family: 'nstb';
	}

	li {
		border-radius: 5px;
		background-color: #f5f5f5;
		width: calc(100% - 0.6rem);
		margin: 0 auto;
	}

	ul li:last-child .conetnt {
		border: none;
	}

	.conetnt {
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 0.3rem 0;
	}

	li .conetnt .title {
		margin-left: 16px;
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		text-align: left;
	}

	.title h2 {
		font-size: 0.35rem;
		color: #000;
	}

	ul li .conetnt .title p {
		color: #999;
		margin-top: 0.1rem;
	}

	.conetnt .radio {
		padding-right: 10px;
	}

	.advice {
		height: 0.5rem;
		width: 0.5rem;
		display: inline-block;
		background-repeat: no-repeat;
		background-position: center;
		vertical-align: middle;
		background-size: contain;
	}

	.advice-hl {
		background-image: url(../assets/image/xuanze.png);
	}

	.advice.advice-nor {
		background-image: url(../assets/image/weixuanze.png);
	}

	.Btnsub {
		color: rgb(255, 255, 255);
		background: #E31837;
		border: none;
		margin: 20px auto 0 auto;
		width: calc(100% - 0.6rem);
		border-radius: 5px;
		height: 35px;
		line-height: 35px;
	}

	/deep/.van-field__control {
		color: #000;
		font-family: 'nstb';
		font-size: .36rem;
	}
</style>