<template>
	<div class="lottery">
		<!-- <div class="jpm_logoa"><img src="@/assets/image/jpmlogol.svg" /></div> -->
		<tab-bar></tab-bar>
		<header>
			<div class="g-header">
				<div class="left"></div>
				<div class="middle fs-18">J.P.Morgan {{ $t('zengzhi') }}</div>
				<div class="right">
					<div class="bg-icon bg-icon-setting"></div>
				</div>
			</div>
			<div class="blockHeight"></div>
		</header>
		<div class="g-content mescroll">
			<!-- <div> -->
			<div class="tab-box-wrap">
				<div class="tab-box">
					<li v-for="(item, index) in productlist" :key="index" class="tab"
						:class="curr == index + 1 ? 'active' : ''" @click="selectproduct(item,index)">
						<p>{{ item.productname }}<span>{{ item.interest }}%</span></p>
					</li>
					<!-- <div class="tab_lines" :style="activeLines"></div> -->
				</div>

				<div class="yuepic">
					<p>
						<span>{{ $t("起购金额") }}</span>
						<span><font class="boldfontblack">{{ minbuyprice }}</font> {{ $t("qfh") }}</span>
					</p>
					<p>
						<span>{{ $t("可用余额") }}</span>
						<span><font class="boldfontblack">{{ balance }}</font> {{ $t("qfh") }}</span>
					</p>
					<p>
						<span>{{ $t("已存储金额") }}</span>
						<span><font class="boldfontgreen">{{ Number(savings).toFixed(2) }} <font class="boldfontgreens">↑</font></font> {{ $t("qfh") }}</span>
					</p>
				</div>
				<div class="yuepic">
					<p>
						<span>{{ $t("预计收益") }}</span>
						<span><font class="boldfontgreen">{{ yujishouyi }} <font class="boldfontgreens">↑</font></font> {{ $t("qfh") }}</span>
					</p>
					<p>
						<span>{{ $t("投资日期") }}</span>
						<span>{{ addtime }}</span>
					</p>
					<p>
						<span>{{ $t("开始计算利息") }}</span>
						<span>{{ starttime }}</span>
					</p>
					<p>
						<span>{{ $t("结算时间") }}</span>
						<span>{{ endtime }}</span>
					</p>
				</div>
				<van-cell center :title="$t('自动复购')">
					<van-switch v-model="checked" size="16px" active-color="#07c160" inactive-color="#fb002d" />
				</van-cell>
				<van-cell-group inset>
					<van-field v-model="number" type="number" center clearable :label="$t('储蓄金额')" :placeholder="$t('请输入储蓄金额')">
						<template #button>
							<van-button size="small" type="primary" @click="allmoney">{{ $t("全部投入") }}</van-button>
						</template>
					</van-field>
				</van-cell-group>
				<van-button type="primary" size="big" @click="addsaving">{{ $t("购买") }}</van-button>
			</div>
		</div>
	</div>
</template>
<script>
	import TabBar from "@/components/tabbar/TabBar.vue";
	import homeApi from "@/api/home";
	export default {
		name: "Savings",
		components: {
			TabBar,
		},
		data() {
			return {
				productlist: [],
				curr: 1,
				productid: 0,
				number: 0,
				daynum: 0,
				interest: 0,
				checked: false,
				button: '111',
				addtime: '',
				starttime: '',
				endtime: '',
				yujishouyi: 0,
				balance: 0,
				savings: 0,
				minbuyprice: 0,
				type: 1
			};
		},
		computed: {},
		watch: {
			daynum(value) {
				let d = new Date();
				let year = d.getFullYear();
				let month = (d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1);
				let day = d.getDate();
				let hour = (d.getHours() < 10 ? '0' + d.getHours() : d.getHours());
				let minute = (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes());
				let second = (d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds());
				this.addtime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second

				let endTime = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000);


				let Y = endTime.getFullYear();
				let M = (endTime.getMonth() + 1 < 10 ? '0' + (endTime.getMonth() + 1) : endTime.getMonth() + 1);
				let D = (endTime.getDate() < 10 ? '0' + (endTime.getDate()) : endTime.getDate());


				let h = (endTime.getHours() < 10 ? '0' + (endTime.getHours()) : endTime.getHours());

				let m = (endTime.getMinutes() < 10 ? '0' + (endTime.getMinutes()) : endTime.getMinutes());
				let s = (endTime.getSeconds() < 10 ? '0' + (endTime.getSeconds()) : endTime.getSeconds());

				this.starttime = Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s

				let endTimenextday = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 +
					value * 24 * 60 * 60 * 1000);

				let NY = endTimenextday.getFullYear();
				let NM = (endTimenextday.getMonth() + 1 < 10 ? '0' + (endTimenextday.getMonth() + 1) : endTimenextday
					.getMonth() + 1);
				let ND = (endTimenextday.getDate() < 10 ? '0' + (endTimenextday.getDate()) : endTimenextday.getDate());
				let nh = (endTimenextday.getHours() < 10 ? '0' + (endTimenextday.getHours()) : endTimenextday.getHours());
				let nm = (endTimenextday.getMinutes() < 10 ? '0' + (endTimenextday.getMinutes()) : endTimenextday
					.getMinutes());
				let ns = (endTimenextday.getSeconds() < 10 ? '0' + (endTimenextday.getSeconds()) : endTimenextday
					.getSeconds());
				this.endtime = NY + '-' + NM + '-' + ND + ' ' + nh + ':' + nm + ':' + ns
			},
			number(value) {
				this.yujishouyi = (value * this.interest / 100).toFixed(2);
			},
			interest(value) {
				this.yujishouyi = (value * this.number / 100).toFixed(2);
			},
			checked(value) {
				if (value === false) {
					this.type = 1;
				} else {
					this.type = 2;
				}
			}
		},
		methods: {
			selectproduct(obj, index) {
				this.productid = obj.id;
				this.daynum = obj.daynum;
				this.interest = obj.interest;
				this.minbuyprice = obj.minbuyprice;
				this.curr = index + 1;
			},
			allmoney() {
				this.number = this.balance
			},
			addsaving() {
				this.$toast.loading({
					message: this.$t('jiazaizhong'),
					forbidClick: true,
					duration: 0,
				});
				if(Number(this.number)<Number(this.minbuyprice)){
					this.$toast(this.$t('起购金额') + ' ' + this.minbuyprice + ' ' + this.$t('qfh'));
					return
				}
				homeApi.addsaving(this.type, this.number, this.productid).then((data) => {
					this.$toast.clear();
					if (data.code == 1) {
						location.reload();
					}
				}).catch((err) => {
					this.$toast.clear();
					this.$toast.fail(this.$t(err.msg));
				});
			}
		},
		mounted() {
			this.$toast.loading({
				message: this.$t('jiazaizhong'),
				forbidClick: true,
				duration: 0,
			});
			homeApi.getProductlist().then((data) => {
				this.$toast.clear();
				if (data.code == 1) {
					this.productlist = data.data
					this.productid = data.data[0].id
					this.daynum = data.data[0].daynum
					this.interest = data.data[0].interest
					this.minbuyprice = data.data[0].minbuyprice

				}
			}).catch((err) => {
				this.$toast.clear();
				this.$toast.fail(this.$t(err.msg));
			});
			homeApi.assetsinfo().then((data) => {
				this.$toast.clear();
				if (data.code == 1) {
					this.balance = data.data.balance
					this.savings = data.data.savings
				}
			}).catch((err) => {
				this.$toast.clear();
				this.$toast.fail(err.msg);
			});

		},
		created() {},
	};
</script>
<style lang="less" scoped>
	.lottery {
		height: 100%;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		background: #fff;
		// background: rgb(40, 195, 100);
		// background: linear-gradient(160deg, rgba(40, 195, 100, 1) 0%, rgba(0, 150, 60, 1) 40%);
	}

	.mescroll {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		overflow-y: auto;
	}

	.tab-box-wrap {
		position: relative;
	}

	.tab-box-content {
		width: 100%;
		padding: 0;
		box-sizing: border-box;
	}

	.tab-box {
		// display: flex;
		// -webkit-box-align: center;
		// -ms-flex-align: center;
		// align-items: center;
		// border-left: 1px solid #4bb3e8;
		// border-top: 1px solid #4bb3e8;
		width: 100%;
		padding: 0 16px;
		margin: 0 auto;
		position: relative;
		background: #f6f7f9;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.tab {
		height: 44px;
		font-size: 0.24rem;
		text-align: center;
		// width: 16.66%;
		display: inline-block;
		color: #aaa;
		position: relative;
		z-index: 2;
	}

	.tab p {
		margin-top: 5px;
	}

	.tab p span {
		display: block;
		font-family: 'nstb';
		line-height: 6px;
		font-size: 0.26rem;
	}

	.active {
		color: #00c47d;
		font-family: 'nstb';
		font-size: 0.28rem;
		text-decoration: none;
	}

	.tab_lines {
		position: absolute;
		height: 44px;
		background: #00c47d;
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		z-index: 0;
	}

	.swiper-container {
		// height: 300px;
		width: 100%;

		.swiper-wrapper {
			.swiper-slide {}
		}
	}

	.level-box {
		background-color: #fff;
		box-sizing: border-box;
		padding-bottom: 100px;
	}

	.level {
		text-align: left;
		position: relative;
		padding: 1rem 0.6rem 0.5rem 0.6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.level1 {
		text-align: left;
		position: relative;
		padding: 0.5rem 0.6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #f6f7f9;
	}

	.level2 {
		text-align: left;
		position: relative;
		padding: 0.5rem 0.6rem;
		// border-bottom: .02rem solid #ddd;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.level3 {
		text-align: left;
		position: relative;
		padding: 0.5rem 0.6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #f6f7f9;
	}

	.level-name {
		font-family: 'nstb';
		font-size: 0.32rem;
		color: #000;
		margin-bottom: 3px;
	}

	.level-name_a {
		font-family: 'nstb';
		font-size: 0.32rem;
		color: #000;
		margin-bottom: 3px;
	}

	.level-name_b {
		font-family: 'nstb';
		font-size: 0.32rem;
		color: #000;
		margin-bottom: 3px;
	}

	.level-name_c {
		font-family: 'nstb';
		font-size: 0.32rem;
		color: #000;
		margin-bottom: 3px;
	}

	.people-box {
		font-family: 'nstb';
		color: #000;
		display: flex;
		align-items: center;
		justify-content: space-between;

		span {}

		.icon {
			margin-right: 6px;
			width: 16px;
			height: 16px;
		}
	}

	.level-pix {
		width: 50px;
		text-align: left;
		margin-right: 15px;

		img {
			width: 100%;
		}
	}

	.text-box {
		width: calc(80% - 65px);

		.text {
			font-size: 0.26rem;
			line-height: 0.44rem;
			color: #ccc;
		}

		.price {
			font-family: 'nstb';
			margin-left: 5px;
			font-size: 0.3rem;
			color: #aaa;
		}
	}

	.title {
		text-align: right;
	}

	.fjjt {
		margin-bottom: 22px;

		img {
			width: 16px;
		}
	}



	.jpm_logoa {
		width: 80%;
		position: absolute;
		top: -40px;
		right: -20px;

		img {
			width: 100%;
		}

	}



	// p span {
	// 	display: inline-block;
	// 	width: 50%
	// }
	
	.yuepic{
		width: calc(100% - 32px);
		padding: 0.4rem 0;
		margin: 0 auto;
		border-bottom: 1px solid #f6f7f9;
	}
	.yuepic p{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.1rem 0;
	}
	
	
	.van-cell-group{
		margin: 0;
	}
	
	.van-button--primary{
		border: none;
	}
	
	.van-button--small{
		background: #c6c7c9;
		height: 24px;
		line-height: 24px;
		font-size: 0.26rem;
	}
	
	.van-button--big{
		width: calc(100% - 32px);
		margin: 0 auto;
		background: #00c47d;
		margin-top: 20px;
		height: 35px;
		border-radius: 0.1rem;
	}
	
	.boldfontgreen{
		font-family: 'nstb';
		color: #00c47d;
	}
	
	.boldfontgreens{
		font-family: 'nst';
		color: #00c47d;
		font-size: 0.2rem;
	}
	
	.boldfontblack{
		font-family: 'nstb';
		color: #000;
	}
</style>