<template>
	<div class="lottery">
		<!-- <div class="jpm_logoa"><img src="@/assets/image/jpmlogol.svg" /></div> -->
		<tab-bar></tab-bar>
		<header>
			<div class="g-header">
				<div class="left"></div>
				<div class="middle fs-18">{{ $t('jiaoyidatingabc') }}</div>
				<div class="right">
					<div class="bg-icon bg-icon-setting"></div>
				</div>
			</div>
			<div class="blockHeight"></div>
		</header>
		<div class="g-content mescroll">
			<!-- <div> -->
			<div class="tab-box-wrap">
				<div class="tab-box">
					<li v-for="(item, index) in gameList" :key="index" class="tab"
						:class="curr == index + 1 ? 'active' : ''" @click="curr = index + 1">
						{{ item.name }}
					</li>
					<div class="tab_lines" :style="activeLines"></div>
				</div>
				<div class="tab-box-content">
					<div class="swiper-container">
						<div class="swiper-wrapper">
							<div class="swiper-slide" v-for="(item, index) in gameList" :key="index">
								<ul class="level-box">
									<!-- 根据房间类型换图 -->
									<template v-for="(liitem, Liindex) in item.planList" :key="Liindex">
										<li class="level"
											@click="changeroom(liitem.id, liitem.game_id,item.name,liitem.title,liitem.join_limit)"
											v-if="liitem.type == 0">
											<div class="level-pix"><img src="@/assets/image/c_a4.svg" /></div>
											<div class="text-box">
												<div class="level-name">{{ $t('jichulicai') }}</div>
												<!-- <div class="text">
													<span>{{ $t('zuiditouzi') }}</span>
													<span class="price">{{ liitem.min_limit }}</span>
												</div> -->
												<div class="text">
													<span>{{ $t('zuidijinru') }}</span>
													<span class="price">{{ liitem.join_limit }}</span>
												</div>
											</div>
											<div class="title">
												<div class="fjjt"><img src="@/assets/image/jtfj.png"/></div>
												<div class="people-box">
													<img src="@/assets/image/iconline.png" class="icon" alt="" />
													<span>{{ liitem.robot_num }}</span>
												</div>
											</div>
										</li>
										<li class="level1"
											@click="changeroom(liitem.id, liitem.game_id,item.name,liitem.title,liitem.join_limit)"
											v-if="liitem.type == 1">
											<div class="level-pix"><img src="@/assets/image/c_a1.svg" /></div>
											<div class="text-box">
												<div class="level-name_a">{{ $t('zishenlicai') }}</div>
												<!-- <div class="text">
													<span>{{ $t('zuiditouzi') }}</span>
													<span class="price">{{ liitem.min_limit }}</span>
												</div> -->
												<div class="text">
													<span>{{ $t('zuidijinru') }}</span>
													<span class="price">{{ liitem.join_limit }}</span>
												</div>
											</div>
											<div class="title">
												<div class="fjjt"><img src="@/assets/image/jtfj.png"/></div>
												<div class="people-box">
													<img src="@/assets/image/iconline.png" class="icon" alt="" />
													<span>{{ liitem.robot_num }}</span>
												</div>
											</div>
										</li>
										<li class="level2"
											@click="changeroom(liitem.id, liitem.game_id,item.name,liitem.title,liitem.join_limit)"
											v-if="liitem.type == 2">
											<div class="level-pix"><img src="@/assets/image/c_a3.svg" /></div>
											<div class="text-box">
												<div class="level-name_b">{{ $t('dashilicai') }}</div>
												<!-- <div class="text">
													<span>{{ $t('zuiditouzi') }}</span>
													<span class="price">{{ liitem.min_limit }}</span>
												</div> -->
												<div class="text">
													<span>{{ $t('zuidijinru') }}</span>
													<span class="price">{{ liitem.join_limit }}</span>
												</div>
											</div>
											<div class="title">
												<div class="fjjt"><img src="@/assets/image/jtfj.png"/></div>
												<div class="people-box">
													<img src="@/assets/image/iconline.png" class="icon" alt="" />
													<span>{{ liitem.robot_num }}</span>
												</div>
											</div>
										</li>
										<li class="level3"
											@click="changeroom(liitem.id, liitem.game_id,item.name,liitem.title,liitem.join_limit)"
											v-if="liitem.type == 3">
											<div class="level-pix"><img src="@/assets/image/c_a2.svg" /></div>
											<div class="text-box">
												<div class="level-name_c">{{ $t('zhuanjialicai') }}</div>
												<!-- <div class="text">
													<span>{{ $t('zuiditouzi') }}</span>
													<span class="price">{{ liitem.min_limit }}</span>
												</div> -->
												<div class="text">
													<span>{{ $t('zuidijinru') }}</span>
													<span class="price">{{ liitem.join_limit }}</span>
												</div>
											</div>
											<div class="title">
												<div class="fjjt"><img src="@/assets/image/jtfj.png"/></div>
												<div class="people-box">
													<img src="@/assets/image/iconline.png" class="icon" alt="" />
													<span>{{ liitem.robot_num }}</span>
												</div>
											</div>
										</li>
										<!-- <li
                        class="level"
                        @click="changeroom(liitem.id, liitem.game_id)"
                        v-else
                      >
                        <div class="title">
                          <div class="level-name">{{ liitem.title }}</div>
                          <div class="people-box">
                            <img
                              src="@/assets/image/iconline.png"
                              class="icon"
                              alt=""
                            />
                            <span>{{ liitem.robot_num }}</span>
                          </div>
                        </div>
                        <div class="text-box">
                          <div class="text">
                            <span>{{ $t('zuiditouzi') }}</span>
                            <span class="price">{{ liitem.min_limit }}</span>
                          </div>
                          <div class="text">
                            <span>最低进入</span>
                            <span class="price">{{ liitem.join_limit }}</span>
                          </div>
                        </div>
                      </li> -->
									</template>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- </div> -->
		</div>
	</div>
</template>
<script>
	import TabBar from "@/components/tabbar/TabBar.vue";
	import Swiper from "swiper";
	import 'swiper/swiper-bundle.css';
	import homeApi from "@/api/home";
	export default {
		name: "Lottery",
		components: {
			TabBar,
		},
		data() {
			return {
				curr: 1,
				activeLines: "left: 0%; top:42px; width: 16.66%",
				SweipeIndex: 0,
				gameList: "",
			};
		},
		computed: {
			money() {
				console.log(this.$store.state.money);
				return this.$store.state.money;
			},
		},
		watch: {
			curr(newVal) {
				if (newVal == 1) {
					this.activeLines = "left: 0%; top:42px; width: 16.66%";
				} else if (newVal == 2) {
					this.activeLines = "left: 16.66%; top:42px; width: 16.66%";
				} else if (newVal == 3) {
					this.activeLines = "left: 33.32%; top:42px; width: 16.66%";
				} else if (newVal == 4) {
					this.activeLines = "left: 49.98%; top:42px; width: 16.66%";
				} else if (newVal == 5) {
					this.activeLines = "left: 66.64%; top:42px; width: 16.66%";
				} else if (newVal == 6) {
					this.activeLines = "left: 83.3%; top:42px; width: 16.66%";
				} else if (newVal == 7) {
					this.activeLines = "left: 83.3%; top:42px; width: 16.66%";
				} else if (newVal == 8) {
					this.activeLines = "left: 99.96%; top:42px; width: 16.66%";
				} else if (newVal == 9) {
					this.activeLines = "left: 66.66%; top:42px; width: 16.66%";
				}
			},
		},
		methods: {
			handleClickSlide(index) {
				console.log(index);
			},
			tabIndex(target, nodeList) {
				for (let i = 0; i < nodeList.length; i++) {
					if (target === nodeList[i]) {
						return i;
					}
				}
			},
			changeroom(id, game_id, name, title, joinmoeny) {
				// 判断进入金额和自己的金额
				console.log(this.money * 1, joinmoeny * 1)
				if (this.money * 1 < joinmoeny * 1) {
					// alert(1)
					this.$toast(this.$t('Tips.zcbz'))
					return
				}
				this.$router.push({
					path: "/betRoom",
					query: {
						id: id,
						game_id: game_id,
						name: name,
						title: title
					},
				});
			},
			info() {},
		},
		mounted() {
			this.$toast.loading({
				message: this.$t('jiazaizhong'),
				forbidClick: true,
				duration: 0,
			});
			homeApi
				.Lotteryinfo("", "")
				.then((data) => {
					console.log(data.data);
					this.gameList = data.data.gameList;
					document
						.querySelector(".tab-box")
						.addEventListener("click", function(e) {
							let target = e.target; //事件发生的元素
							let nodeList = e.target.parentNode.children; //同级元素集合
							let targetIndex = that.tabIndex(target, nodeList); //调用tabIndex方法，返回值便是元素下标
							mySwiper.slideTo(targetIndex, 300, false);
						});
					let that = this;
					let mySwiper = new Swiper(".swiper-container", {
						// 如果需要分页器
						pagination: ".swiper-pagination",
						// 如果需要前进后退按钮
						nextButton: ".swiper-button-next",
						observer: true,
						observerParents: false,
						prevButton: ".swiper-button-prev",
						onSlideChangeEnd: function(swiper) {
							this.SweipeIndex = swiper.activeIndex;
							console.log(this.SweipeIndex, swiper.activeIndex);
							if (swiper.activeIndex == 0) {
								that.activeLines = "left: 0%; top:42px; width: 16.66%";
							} else if (swiper.activeIndex == 1) {
								that.activeLines = "left: 16.66%; top:42px; width: 16.66%";
							} else if (swiper.activeIndex == 2) {
								that.activeLines = "left: 33.32%; top:42px; width: 16.66%";
							} else if (swiper.activeIndex == 3) {
								that.activeLines = "left: 49.98%; top:42px; width: 16.66%";
							} else if (swiper.activeIndex == 4) {
								that.activeLines = "left: 66.64%; top:42px; width: 16.66%";
							} else if (swiper.activeIndex == 5) {
								that.activeLines = "left: 83.3%; top:42px; width: 16.66%";
							} else if (swiper.activeIndex == 6) {
								that.activeLines = "left: 0%; top:42px; width: 16.66%";
							} else if (swiper.activeIndex == 7) {
								that.activeLines = "left: 33.33%; top:42px; width: 16.66%";
							} else if (swiper.activeIndex == 8) {
								that.activeLines = "left: 66.66%; top:42px; width: 16.66%";
							}
						},
					});
					this.$toast.clear();
				})
				.catch((err) => {
					this.$toast.clear();
					this.$toast.fail(err.msg);
				});
		},
		created() {},
	};
</script>
<style lang="less" scoped>
	.lottery {
		height: 100%;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		background: #fff;
		// background: rgb(40, 195, 100);
		// background: linear-gradient(160deg, rgba(40, 195, 100, 1) 0%, rgba(0, 150, 60, 1) 40%);
	}

	.mescroll {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		overflow-y: auto;
	}

	.tab-box-wrap {
		position: relative;
		background: #f6f7f9;
	}

	.tab-box-content {
		width: 100%;
		padding: 0;
		box-sizing: border-box;
	}

	.tab-box {
		// display: flex;
		// -webkit-box-align: center;
		// -ms-flex-align: center;
		// align-items: center;
		// border-left: 1px solid #4bb3e8;
		// border-top: 1px solid #4bb3e8;
		width: 100%;
		margin: 0 auto;
		font-family: Arial, Helvetica, sans-serif;
		position: relative;
		background: #f6f7f9;
	}

	.tab {
		height: 44px;
		line-height: 44px;
		font-size: 0.26rem;
		text-align: center;
		width: 16.66%;
		display: inline-block;
		color: #aaa;
		position: relative;
		z-index: 2;
	}

	.active {
		color: #936846;
		font-family: 'nstb';
		font-size: 0.28rem;
		text-decoration: none;
	}

	.tab_lines {
		position: absolute;
		top: 42px;
		height: 2px;
		background: #936846;
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		z-index: 0;
	}

	.swiper-container {
		// height: 300px;
		width: 100%;

		.swiper-wrapper {
			.swiper-slide {}
		}
	}

	.level-box {
		background-color: #fff;
		box-sizing: border-box;
		padding-bottom: 100px;
	}

	.level {
		text-align: left;
		position: relative;
		padding: 0.5rem 0.6rem 0.5rem 0.6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.level1 {
		text-align: left;
		position: relative;
		padding: 0.5rem 0.6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #f6f7f9;
		// background: #f6f7f9;
	}

	.level2 {
		text-align: left;
		position: relative;
		padding: 0.5rem 0.6rem;
		// border-bottom: .02rem solid #ddd;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #f6f7f9;
	}

	.level3 {
		text-align: left;
		position: relative;
		padding: 0.5rem 0.6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #f6f7f9;
		border-bottom: 1px solid #f6f7f9;
		// background: #f6f7f9;
	}

	.level-name {
		font-family: 'nstb';
		font-size: 0.32rem;
		color: #000;
		margin-bottom: 3px;
	}

	.level-name_a {
		font-family: 'nstb';
		font-size: 0.32rem;
		color: #000;
		margin-bottom: 3px;
	}

	.level-name_b {
		font-family: 'nstb';
		font-size: 0.32rem;
		color: #000;
		margin-bottom: 3px;
	}

	.level-name_c {
		font-family: 'nstb';
		font-size: 0.32rem;
		color: #000;
		margin-bottom: 3px;
	}

	.people-box {
		font-family: 'nstb';
		color: #000;
		display: flex;
		align-items: center;
		justify-content: space-between;
        span{
		}
		.icon {
			margin-right: 6px;
			width: 16px;
			height: 16px;
		}
	}
	
	.level-pix{
		width: 45px;
		text-align: left;
		margin-right: 15px;
		img{width: 100%;}
	}

	.text-box {
		width: calc(80% - 60px);
		.text {
			font-size: 0.26rem;
			line-height: 0.44rem;
			color: #ccc;
		}

		.price {
			font-family: 'nstb';
			margin-left: 5px;
			font-size: 0.3rem;
			color: #aaa;
		}
	}
	
	.title {
		text-align: right;
	}
	
	.fjjt{
		margin-bottom: 22px;
		img{width: 16px;}
	}
	


	.jpm_logoa {
		width: 80%;
		position: absolute;
		top: -40px;
		right: -20px;

		img {
			width: 100%;
		}

	}
</style>